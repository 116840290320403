import React, {useEffect} from 'react'
import {Form, Button, Container, Row, Col} from "react-bootstrap";
import {post} from '../../controlador/index.controller';
import convertirABase64 from '../tools/convertirABase64';


export default function CreateNovedad(){

    const [texto, setTexto] = React.useState('');
    const [imagen, setImagen] = React.useState('');

    const handleTexto = (event) => {
        setTexto(event.target.value);
    };

    useEffect(() => {
        document.title = "Unit - Novedades";
    }, []);

    const handleImagen = async (event) => {
        const file = event.target.files[0]
        const base64 = await convertirABase64(file)
        setImagen(base64);
    }     

    const crear = async function(){
        let novedad = {
            mostrar: true,
            accion: null,
            accionTipo: 0,
            novedadTipo: 1,
            texto: texto !== "" ? texto : null,
            imagen: imagen
        };

        const token = localStorage.getItem("user-token");
        let postNovedad = await post("api/novedades/", token, novedad);
        const response = await postNovedad.json()
    
        if(postNovedad.status === 201){
            alert("Novedad creada correctamente");
            window.location.href = "/novedades";
        }else{
            alert("Error al crear la novedad. " + postNovedad.data + " " + postNovedad.message);
        }
    }

    return (
        <div>
            <Container>
                <Row className="mb-4">
                    <Col>
                        <h1>Crear novedad</h1>
                        <Form className="mt-5">
                            <Form.Group className="mb-3" >
                                <Form.Text className="text-muted">Imagen </Form.Text>
                                <Form.Text className="text-danger">Obligatorio</Form.Text>
                                <div className="mb-1"></div>
                                <Form.Control type="file" onChange = {(event) => handleImagen(event)}/>
                                <Form.Text className="text-danger">Formatos admitidos: PNG, JPG y JPEG</Form.Text>
                            </Form.Group>

                            <Form.Group className="mb-5" >
                                <Form.Text className="text-muted">Texto </Form.Text>
                                <Form.Control  type="text" onChange = {(event) => handleTexto(event)} id="" placeholder="" />
                            </Form.Group>

                            <Form.Group className="mb-5" >
                                <Button variant="success" href={"#"} onClick={crear}>Guardar novedad</Button>{' '}
                            </Form.Group>
                        </Form>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}