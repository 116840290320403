import React, {useEffect} from 'react'
// eslint-disable-next-line
import {BrowserRouter as Router, Switch, Route, Link} from "react-router-dom";
import {Button, Container, Row, Col, Table} from "react-bootstrap";
import {obtenerAllUsuarios, habilitarUsuario} from '../../controlador/index.controller';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle, faUserSlash, faUser } from '@fortawesome/free-solid-svg-icons'

export default function Usuarios(){
    const [usuarios = [], setUsuarios] = React.useState();

    useEffect(() => {
        document.title = "Unit - Usuarios";
        usuariosExistentes();
    }, []);

    const handleHabilitar = (usuarioId, usuarioNombre, usuariohabilitar) => {
        habilitar(usuarioId, usuarioNombre, usuariohabilitar);
    };

    const usuariosExistentes = async function(){
        const token = localStorage.getItem("user-token")
        const getUsuarios = await obtenerAllUsuarios(token);
        const data = await getUsuarios.json();
        let dataNew = [];
        let aux;

        for(let u of data){
            aux = u;

            if(aux.rucaId === 0){
                aux.rucaNombre = "---";
            }else{
                if(aux.ruca != null){
                    aux.rucaNombre = u.ruca.nombre;
                }
            }

            aux.nombreapellido = aux.nombre + " " + aux.apellido;

            switch(aux.rol){
                case 0:
                    aux.rol = "Usuario";
                    break;
                case 1:
                    aux.rol = "Administrador";
                    break;
                default:
                    break;
            }

            dataNew.push(aux);
        }

        setUsuarios(dataNew);
    }

    const habilitar = async function(usuarioId, usuarioNombre, usuarioHabilitar){
        if (window.confirm(`¿Estás seguro de ${usuarioHabilitar === true ? "habilitar" : "deshabilitar"} a ${usuarioNombre}?`)) {
            const token = localStorage.getItem("user-token");
            const usuario = {
                habilitar: usuarioHabilitar
            }
            const habUsuario = await habilitarUsuario(token, usuarioId, usuario);
            const data = await habUsuario.json();

            if(habUsuario.status === 200){
                alert(`Usuario ${usuarioHabilitar === true ? "habilitado" : "deshabilitado"} correctamente`);
                window.location.reload();
            }else{
                alert(`Error al ${usuarioHabilitar === true ? "habilitar" : "deshabilitar"} usuario. ${data.data} - ${data.message}`);
            }
        }
    }

    return (
        <div>
            <Container>
                <Row className="mb-4">
                    <Col>
                        <h1>Usuarios</h1>
                    </Col>
                    <Col style={{textAlign: "right"}} className="mt-2">
                        <Link to={"/usuarios/nuevo"}>
                            <Button variant="success"><FontAwesomeIcon icon={faPlusCircle} style={{marginRight: 5}} />Crear nuevo usuario</Button>
                        </Link>
                    </Col>
                </Row>

                <Row>
                    <p className="text-muted">{usuarios.length} usuario{usuarios.length === 1 ? "" : "s"} registrado{usuarios.length === 1 ? "" : "s"}</p>
                    <Table striped borderless hover style={{fontSize:13}}>
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Nombre</th>
                                <th>Correo</th>
                                <th>Ruca</th>
                                <th>Rol</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {usuarios.map((u) => (
                                <tr>
                                    <td style={{verticalAlign: "middle", width: 50}}>{u.id}</td>
                                    <td style={{verticalAlign: "middle"}}>{u.nombreapellido}</td>
                                    <td style={{verticalAlign: "middle"}}>{u.correo}</td>
                                    <td style={{verticalAlign: "middle"}}>{u.rucaNombre}</td>
                                    <td style={{verticalAlign: "middle",  width: 140}}>{u.rol}</td>
                                    <td style={{verticalAlign: "middle", width: 140}}><Button variant={u.habilitado === true ? "danger" : "success"} className="w-100" style={{fontSize: 13}} onClick={() => handleHabilitar(u.id, u.nombreapellido, !u.habilitado)} href="#"><FontAwesomeIcon icon={u.habilitado === true ? faUserSlash : faUser} style={{marginRight: 5}} />{u.habilitado === true ? "Deshabilitar" : "Habilitar"}</Button></td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Row>
            </Container>
        </div>
    );
}