import React, {useEffect} from 'react'
// eslint-disable-next-line
import {BrowserRouter as Router, Switch, Route, Link} from "react-router-dom";
import {Button, Container, Row, Col, Form} from "react-bootstrap";
import {obtenerAllRucas, signup} from '../../controlador/index.controller';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle, faUserSlash, faUser } from '@fortawesome/free-solid-svg-icons'

export default function CrearUsuario(){
    const [usuarioNombre, setUsuarioNombre] = React.useState('');
    const [usuarioApellido, setUsuarioApellido] = React.useState('');
    const [usuarioCorreo, setUsuarioCorreo] = React.useState('');
    const [usuarioContrasena1, setUsuarioContrasena1] = React.useState('');
    const [usuarioContrasena2, setUsuarioContrasena2] = React.useState('');
    const [rucas = [], setRucas] = React.useState();
    const [usuarioRuca, setUsuarioRuca] = React.useState('');
    const [roles = [], setRoles] = React.useState();
    const [usuarioRol, setUsuarioRol] = React.useState('');

    useEffect(() => {
        document.title = "Unit - Usuarios";
        rucasExistentes();
        rolesExistentes();
    }, []);

    const handleUsuarioNombre = (event) => {
        setUsuarioNombre(event.target.value);
    };

    const handleUsuarioApellido = (event) => {
        setUsuarioApellido(event.target.value);
    };

    const handleUsuarioCorreo = (event) => {
        setUsuarioCorreo(event.target.value);
    };

    const handleUsuarioContrasena1 = (event) => {
        setUsuarioContrasena1(event.target.value);
    };

    const handleUsuarioContrasena2 = (event) => {
        setUsuarioContrasena2(event.target.value);
    };

    const callbackUsuarioRuca = (event) => {
        setUsuarioRuca(event.target.value);
    };

    const callbackUsuarioRol = (event) => {
        setUsuarioRol(event.target.value);
    };

    const rucasExistentes = async function(){
        const getRucas = await obtenerAllRucas();
        const data = await getRucas.json();
        setRucas(data);
    }

    const rolesExistentes = async function(){
        let data = [];
        data.push({
            rol: "Usuario",
            id: 0
        });
        data.push({
            rol: "Administrador",
            id: 1
        });
        setRoles(data);
    }

    const crear = async function(){
        const usuario = {
            nombre: usuarioNombre,
            apellido: usuarioApellido,
            correo: usuarioCorreo,
            contrasena: usuarioContrasena1,
            rol: usuarioRol.replace("rol", ""),
            habilitado: true,
            rucaId: usuarioRuca.replace("ruca", "")
        }

        const postSignup = await signup(usuario);
        const data = await postSignup.json();
    
        if(postSignup.status === 201){
            alert("Usuario creado correctamente");
            window.location.href = "/usuarios/";
        }else{
            alert("Error al crear la unidad. " + data.data + " " + data.message);
        }
    }

    return (
        <div>
            <Container>
                <Row>
                    <Col>
                        <h1 className="mb-4">Crear usuario</h1>
                        <Form>
                            <Form.Group className="mb-3" >
                                <Form.Text className="text-muted">Nombre </Form.Text>
                                <Form.Text className="text-danger">Obligatorio</Form.Text>
                                <Form.Control className="w-50" onChange = {(event) => handleUsuarioNombre(event)} type="text" id="" placeholder="" />
                            </Form.Group>
                            <Form.Group className="mb-3" >
                                <Form.Text className="text-muted">Apellido </Form.Text>
                                <Form.Text className="text-danger">Obligatorio</Form.Text>
                                <Form.Control className="w-50" onChange = {(event) => handleUsuarioApellido(event)} type="text" id="" placeholder="" />
                            </Form.Group>
                            <Form.Group className="mb-3" >
                                <Form.Text className="text-muted">Correo </Form.Text>
                                <Form.Text className="text-danger">Obligatorio</Form.Text>
                                <Form.Control className="w-50" onChange = {(event) => handleUsuarioCorreo(event)} type="text" id="" placeholder="" />
                            </Form.Group>
                            <Form.Group className="mb-3" >
                                <Form.Text className="text-muted">Contraseña </Form.Text>
                                <Form.Text className="text-danger">Obligatorio</Form.Text>
                                <Form.Control className="w-50" onChange = {(event) => handleUsuarioContrasena1(event)} type="text" id="" placeholder="" />
                            </Form.Group>
                            <Form.Group className="mb-3" >
                                <Form.Text className="text-muted">Confirmar contraseña </Form.Text>
                                <Form.Text className="text-danger">Obligatorio</Form.Text>
                                <Form.Control className="w-50" onChange = {(event) => handleUsuarioContrasena2(event)} type="text" id="" placeholder="" />
                            </Form.Group>
                            <Form.Group className="mb-3" >
                                <Form.Text className="text-muted">Ruca </Form.Text>
                                <Form.Select className="w-50"  onChange = {(event) => callbackUsuarioRuca(event)}>
                                    <option value="0">Seleccionar Ruca...</option>
                                    {rucas.map((r) => (
                                        <option id={"ruca" + r.id} value={r.id}>{r.nombre}</option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                            <Form.Group className="mb-5" >
                                <Form.Text className="text-muted">Rol </Form.Text>
                                <Form.Text className="text-danger">Obligatorio</Form.Text>
                                <Form.Select className="w-50"  onChange = {(event) => callbackUsuarioRol(event)}>
                                    <option value="0">Seleccionar rol...</option>
                                    {roles.map((r) => (
                                        <option id={"rol" + r.id} value={r.id}>{r.rol}</option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                        </Form>

                        <Form.Group className="mb-5" >
                                <Button variant="success" href={"#"} onClick={crear}>Crear usuario</Button>
                            </Form.Group>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}