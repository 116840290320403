import React, {useEffect} from 'react'
import {Form, Button, Container, Row, Col, Card} from "react-bootstrap";
import {BrowserRouter as Router, Switch, Route, Link} from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle, faPen, faTrash } from '@fortawesome/free-solid-svg-icons'
import {obtenerNovedades} from '../../controlador/index.controller';
import convertirABase64 from '../tools/convertirABase64';


export default function ShowNovedades(){
    const rutaArchivos = "https://formacion.secdevstest.com.ar/data";
    const [novedades, setNovedades] = React.useState([]);

    useEffect(() => {
        document.title = "Unit - Novedades";
        novedadesExistentes();
    }, []);

    const novedadesExistentes = async function(){
        const token = localStorage.getItem("user-token")
        const n = await obtenerNovedades(token);
        setNovedades(n);
    }

    const handleEliminar = (unidadId) => {
        //eliminar(unidadId, unidadNombre);
    };

    return (
        <div>
            <Container>
                <Row className="mb-4">
                    <Col>
                        <h1>Novedades</h1>
                    </Col>
                    <Col style={{textAlign: "right"}} className="mt-2">
                        <Link to={"/novedades/nueva"}>
                            <Button variant="success"><FontAwesomeIcon icon={faPlusCircle} style={{marginRight: 5}} />Crear nueva novedad</Button>
                        </Link>
                    </Col>
                </Row>

                <Row>
                   
                    {novedades.map((n) => (
                        <Col xs={4} className="mb-3">
                            <Card>
                                <Card.Img variant="top" src={rutaArchivos + n.imagen.uri} height={250} />
                                <Card.Body>
                                    <Card.Text>
                                        <p>{n.texto}</p>
                                    </Card.Text>

                                    <Button variant="danger" onClick={() => handleEliminar(n.id)} href="#"><FontAwesomeIcon icon={faTrash} style={{marginRight: 5}} />Eliminar</Button>
                                </Card.Body>
                            </Card>
                        </Col>

                    ))}
                
                </Row>
            </Container>
        </div>
    );
}