import urlWebServices from '../controlador/webServices.js';
import constantes from '../controlador/constantes.js';

const urlApi = constantes.dev ? constantes.dev_api_uri : constantes.prod_api_uri;

export const crearEje = async function (token, body){
    const url = urlWebServices.crearEje;

    try{
        let response = await fetch(url, {
                method: 'POST',
                mode: "cors",
                headers:{
                    'Accept':'application/x-www-form-urlencoded',
                    'Origin':'http://localhost:3000',
                    'Content-Type': 'application/json',
                    'x-access-token': token},
                body: JSON.stringify(body), 
            });
            
        let res = response.status;
        return res;

    } catch(error){
        console.log("error",error);
        return ({rdo:1,mensaje:"Ha ocurrido un error"});                
    };
}

export const obtenerEjes = async function (token){
    const url = urlWebServices.obtenerEjes;

    try{
        let response = await fetch(url, {
                method: 'GET',
                mode: "cors",
                headers:{
                    'Accept':'application/x-www-form-urlencoded',
                    'Origin':'http://localhost:3000',
                    'Content-Type': 'application/json',
                    'x-access-token': token},
            });
            
        return await response.json();

    } catch(error){
        console.log("error",error);
        return ({rdo:1,mensaje:"Ha ocurrido un error"});                
    };
}

export const obtenerSecciones = async function (token){
    const url = urlWebServices.obtenerSecciones;

    try{
        let response = await fetch(url, {
                method: 'GET',
                mode: "cors",
                headers:{
                    'Accept':'application/x-www-form-urlencoded',
                    'Origin':'http://localhost:3000',
                    'Content-Type': 'application/json',
                    'x-access-token': token}
            });
            
        return await response.json();

    } catch(error){
        
        return ({rdo:1,mensaje:"Ha ocurrido un error"});                
    };
}

export const crearUnidad = async function (token, body){
    const url = urlWebServices.crearUnidad;

    try{
        let response = await fetch(url, {
                method: 'POST',
                mode: "cors",
                headers:{
                    'Accept':'application/x-www-form-urlencoded',
                    'Origin':'http://localhost:3000',
                    'Content-Type': 'application/json',
                    'x-access-token': token},
                body: JSON.stringify(body), 
            });
            
        return await response.json();
    } catch(error){
        console.log("error",error);
        return ({rdo:1,mensaje:"Ha ocurrido un error"});                
    };
}

export const obtenerMarchas = async function (token){
    const url = urlWebServices.obtenerMarchas;

    try{
        let response = await fetch(url, {
                method: 'GET',
                mode: "cors",
                headers:{
                    'Accept':'application/x-www-form-urlencoded',
                    'Origin':'http://localhost:3000',
                    'Content-Type': 'application/json',
                    'x-access-token': token},
            });
            
        return await response.json();

    } catch(error){
        
        return ({rdo:1,mensaje:"Ha ocurrido un error"});                
    };
}

export const obtenerUnidades = async function (token){
    const url = urlWebServices.obtenerUnidades;

    try{
        let response = await fetch(url, {
            method: 'GET',
            mode: "cors",
            headers:{
                'Accept':'application/x-www-form-urlencoded',
                'Origin':'http://localhost:3000',
                'Content-Type': 'application/json',
                'x-access-token': token},
            });
            
        return response;

    } catch(error){
        
        return ({rdo:1,mensaje:"Ha ocurrido un error"});                
    };
}

export const obtenerUnidad = async function (token, unidadId){
    const url = urlWebServices.obtenerUnidad + unidadId;

    try{
        let response = await fetch(url, {
            method: 'GET',
            mode: "cors",
            headers:{
                'Accept':'application/x-www-form-urlencoded',
                'Origin':'http://localhost:3000',
                'Content-Type': 'application/json',
                'x-access-token': token},
            });
            
        return await response.json();

    } catch(error){
        
        return ({rdo:1,mensaje:"Ha ocurrido un error"});                
    };
}

export const eliminarUnidad = async function (token, unidadId){
    const url = urlWebServices.eliminarUnidad + unidadId;

    try{
        let response = await fetch(url, {
            method: 'DELETE',
            mode: "cors",
            headers:{
                'Accept':'application/x-www-form-urlencoded',
                'Origin':'http://localhost:3000',
                'Content-Type': 'application/json',
                'x-access-token': token},
            });
            
        return await response.json();
    } catch(error){
        return ({rdo:1,mensaje:"Ha ocurrido un error"});                
    };
}

export const modificarUnidad = async function (token, unidadId, body){
    const url = urlWebServices.modificarUnidad + unidadId;

    try{
        let response = await fetch(url, {
                method: 'PUT',
                mode: "cors",
                headers:{
                    'Accept':'application/x-www-form-urlencoded',
                    'Origin':'http://localhost:3000',
                    'Content-Type': 'application/json',
                    'x-access-token': token},
                body: JSON.stringify(body), 
            });
            
        return await response.json();
    } catch(error){
        alert(error);
        return ({rdo:1,mensaje:"Ha ocurrido un error"});                
    };
}

export const login = async function (body){
    const url = urlWebServices.login;

    try{
        let response = await fetch(url, {
                method: 'POST',
                mode: "cors",
                headers:{
                    'Accept':'application/x-www-form-urlencoded',
                    'Origin':'http://localhost:3000',
                    'Content-Type': 'application/json'},
                body: JSON.stringify(body), 
            });
            
        return response;
    } catch(error){
        console.log("error",error);
        return ({rdo:1,mensaje:"Ha ocurrido un error"});                
    };
}

export const obtenerAllUsuarios = async function (token){
    const url = urlWebServices.obtenerUsuarios;

    try{
        let response = await fetch(url, {
                method: 'GET',
                mode: "cors",
                headers:{
                    'Accept':'application/x-www-form-urlencoded',
                    'Origin':'http://localhost:3000',
                    'Content-Type': 'application/json',
                    'x-access-token': token},
            });
            
        return response;
    } catch(error){
        console.log("error",error);
        return ({rdo:1,mensaje:"Ha ocurrido un error"});                
    };
}

export const habilitarUsuario = async function (token, usuarioId, body){
    const url = urlWebServices.habilitarUsuario + usuarioId;

    try{
        let response = await fetch(url, {
                method: 'PUT',
                mode: "cors",
                headers:{
                    'Accept':'application/x-www-form-urlencoded',
                    'Origin':'http://localhost:3000',
                    'Content-Type': 'application/json',
                    'x-access-token': token},
                body: JSON.stringify(body), 
            });
            
        return response;
    } catch(error){
        alert(error);
        return ({rdo:1,mensaje:"Ha ocurrido un error"});                
    };
}

export const obtenerAllRucas = async function (){
    const url = urlWebServices.obtenerRucas;

    try{
        let response = await fetch(url, {
                method: 'GET',
                mode: "cors",
                headers:{
                    'Accept':'application/x-www-form-urlencoded',
                    'Origin':'http://localhost:3000',
                    'Content-Type': 'application/json'},
            });
            
        return response;
    } catch(error){
        console.log("error",error);
        return ({rdo:1,mensaje:"Ha ocurrido un error"});                
    };
}

export const signup = async function (body){
    const url = urlWebServices.signup;

    try{
        let response = await fetch(url, {
                method: 'POST',
                mode: "cors",
                headers:{
                    'Accept':'application/x-www-form-urlencoded',
                    'Origin':'http://localhost:3000',
                    'Content-Type': 'application/json'},
                body: JSON.stringify(body), 
            });
            
        return response;
    } catch(error){
        console.log("error",error);
        return ({rdo:1,mensaje:"Ha ocurrido un error"});                
    };
}

export const obtenerNovedades = async function (token){
    const url = urlWebServices.obtenerNovedades;

    try{
        let response = await fetch(url, {
            method: 'GET',
            mode: "cors",
            headers:{
                'Accept':'application/x-www-form-urlencoded',
                'Origin':'http://localhost:3000',
                'Content-Type': 'application/json',
                'x-access-token': token},
            });
            
        return await response.json();

    } catch(error){
        
        return ({rdo:1,mensaje:"Ha ocurrido un error"});                
    };
}

export const post = async function (url, token, body){
    try{
        let response = await fetch(urlApi + url, {
                method: 'POST',
                mode: "cors",
                headers:{
                    'Accept':'application/x-www-form-urlencoded',
                    'Origin':'http://localhost:3000',
                    'Content-Type': 'application/json',
                    'x-access-token': token},
                body: JSON.stringify(body), 
            });
            
        return response;
    } catch(error){
        console.log("error",error);
        return ({rdo:1,mensaje:"Ha ocurrido un error"});                
    };
}

export const put = async function (url, token, body){
    try{
        let response = await fetch(urlApi + url, {
                method: 'PUT',
                mode: "cors",
                headers:{
                    'Accept':'application/x-www-form-urlencoded',
                    'Origin':'http://localhost:3000',
                    'Content-Type': 'application/json',
                    'x-access-token': token},
                body: JSON.stringify(body), 
            });
            
        return response;
    } catch(error){
        console.log("error",error);
        return ({rdo:1,mensaje:"Ha ocurrido un error"});                
    };
}
