import React from "react";
import {BrowserRouter as Router, Switch, Route} from "react-router-dom";
import {Row, Col} from "react-bootstrap";
import './css/navbar.css';

import NavBar from "./componentes/navbar/NavBar";
import Login from "./componentes/login/Login";
import CrearUnidad from "./componentes/unidades/CrearUnidad";
import ModificarUnidad from "./componentes/unidades/ModificarUnidad";
import Unidades from "./componentes/unidades/Unidades";
import CrearEje from "./componentes/ejes/CrearEje";
import UserController from "./componentes/login/UserController";
import Usuarios from "./componentes/usuarios/Usuarios";
import CrearUsuario from "./componentes/usuarios/CrearUsuario";
import ShowNovedades from "./componentes/novedades/ShowNovedades";
import CreateNovedad from "./componentes/novedades/CreateNovedad";

export function App(){
    return (
        <div>
        <Router>
            <Row className="m-0">
                    <Switch>
                        <Route exact path="/">
                            <UserController/>
                        </Route>
                        <Route exact path="/login">
                            <Login/>
                        </Route>
                        <Route exact path="/unidades">
                            
                            <Col xs={2} style={{position:"fixed", height:"100%", background:"#183153", paddingRight: 0}}>
                                <NavBar/>
                            </Col>
                            <Col xs={2}>

                            </Col>
                            <Col xs={10} className="pt-4">
                                <UserController/>
                                <Unidades/>
                            </Col>
                        </Route>
                        <Route exact path="/unidades/nueva">
                            <Col xs={2} style={{position:"fixed", height:"100%", background:"#183153", paddingRight: 0}}>
                                <NavBar/>
                            </Col>
                            <Col xs={2}>

                            </Col>
                            <Col xs={10} className="pt-4">
                                <UserController/>
                                <CrearUnidad/>
                            </Col>
                        </Route>
                        <Route exact path="/unidades/modificar">
                            <Col xs={2} style={{position:"fixed", height:"100%", background:"#183153", paddingRight: 0}}>
                                <NavBar/>
                            </Col>
                            <Col xs={2}>

                            </Col>
                            <Col xs={10} className="pt-4">
                                <UserController/>
                                <CrearUnidad/>
                            </Col>
                        </Route>
                        <Route exact path="/ejes/nuevo">
                            <Col xs={2} style={{position:"fixed", height:"100%", background:"#183153", paddingRight: 0}}>
                                <NavBar/>
                            </Col>
                            <Col xs={2}>

                            </Col>
                            <Col xs={10} className="pt-4">
                                <UserController/>
                                <CrearEje/>
                            </Col>
                        </Route>
                        <Route exact path="/usuarios">
                            <Col xs={2} style={{position:"fixed", height:"100%", background:"#183153", paddingRight: 0}}>
                                <NavBar/>
                            </Col>
                            <Col xs={2}>

                            </Col>
                            <Col xs={10} className="pt-4">
                                <UserController/>
                                <Usuarios/>
                            </Col>
                        </Route>
                        <Route exact path="/usuarios/nuevo">
                            <Col xs={2} style={{position:"fixed", height:"100%", background:"#183153", paddingRight: 0}}>
                                <NavBar/>
                            </Col>
                            <Col xs={2}>

                            </Col>
                            <Col xs={10} className="pt-4">
                                <UserController/>
                                <CrearUsuario/>
                            </Col>
                        </Route>
                        <Route exact path="/novedades">
                            <Col xs={2} style={{position:"fixed", height:"100%", background:"#183153", paddingRight: 0}}>
                                <NavBar/>
                            </Col>
                            <Col xs={2}>

                            </Col>
                            <Col xs={10} className="pt-4">
                                <UserController/>
                                <ShowNovedades/>
                            </Col>
                        </Route>
                        <Route exact path="/novedades/nueva">
                            <Col xs={2} style={{position:"fixed", height:"100%", background:"#183153", paddingRight: 0}}>
                                <NavBar/>
                            </Col>
                            <Col xs={2}>

                            </Col>
                            <Col xs={10} className="pt-4">
                                <UserController/>
                                <CreateNovedad/>
                            </Col>
                        </Route>
                    </Switch>
                
                
            </Row>
        </Router>
        
            
            <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossOrigin="anonymous"/>
        </div>
        
    )
}