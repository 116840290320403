import React from 'react'
import {Form, Button, Container, Row, Col} from "react-bootstrap";
import {login} from '../../controlador/index.controller';

export default function Login(){
    const [correo, setCorreo] = React.useState('');
    const [contrasena, setContrasena] = React.useState('');

    const handleCorreo = (event) => {
        setCorreo(event.target.value);
    };

    const handleContrasena = (event) => {
        setContrasena(event.target.value);
    };

    const ingresar = async function(){
        const usuario = {
            correo: correo,
            contrasena: contrasena
        }

        let postLogin = await login(usuario);
        let response = await postLogin.json()

        if(postLogin.status === 200){
            if(response.rol === 1){
                guardarDatos(response);
            }else{
                alert("Sin permiso");
            }
            
            window.location.href = "/";
        }else{
            alert("Error al iniciar sesión");
        }
    }

    const guardarDatos = function(datos){
        localStorage.setItem("user-token", datos.token);
        localStorage.setItem("user-nombre", datos.nombre);
        localStorage.setItem("user-apellido", datos.apellido);
        localStorage.setItem("user-id", datos.id);
    }


    return (
        <div>
        <Container>
            <Row>
                <Col xs={4}>

                </Col>
                <Col xs={4} style={{marginTop:200}}>
                    <Form>
                        <h1 className="mb-4">Ingresar</h1>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Control type="email" onChange = {(event) => handleCorreo(event)} placeholder="Correo" />
                        </Form.Group>

                        <Form.Group className="mb-4" controlId="formBasicPassword">
                            <Form.Control type="password" onChange = {(event) => handleContrasena(event)} placeholder="Contraseña" />
                        </Form.Group>

                        <Button variant="primary" type="submit" className="w-100" href={"#"} onClick={ingresar}>
                            Ingresar
                        </Button>
                    </Form>
                </Col>
                <Col xs={4}>
                    
                </Col>
            </Row>
        </Container>
            
        </div>
    );
}
