import React from "react";
// eslint-disable-next-line
import {BrowserRouter as Router, Link} from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileAlt, faSignOutAlt, faUser, faNewspaper} from '@fortawesome/free-solid-svg-icons'
import '../../css/navbar.css';


export default function NavBar(){

    const btnUnidades = function(){
        if(window.location.pathname.includes("/unidades")){
            return (<button className="botonPadre botonSeleccionado mt-5"><FontAwesomeIcon icon={faFileAlt} style={{marginRight: 10, marginLeft: 10}} />Unidades</button>);
        }else{
            return (<button className="botonPadre mt-5"><FontAwesomeIcon icon={faFileAlt} style={{marginRight: 10, marginLeft: 10}} />Unidades</button>);
        }
    }

    const btnUsuarios = function(){
        if(window.location.pathname.includes("/usuarios")){
            return (<button className="botonPadre botonSeleccionado mt-2"><FontAwesomeIcon icon={faUser} style={{marginRight: 10, marginLeft: 10}} />Usuarios</button>);
        }else{
            return (<button className="botonPadre mt-2"><FontAwesomeIcon icon={faUser} style={{marginRight: 10, marginLeft: 10}} />Usuarios</button>);
        }
    }

    const btnNovedades = function(){
        if(window.location.pathname.includes("/novedades")){
            return (<button className="botonPadre botonSeleccionado mt-2"><FontAwesomeIcon icon={faNewspaper} style={{marginRight: 8, marginLeft: 8}} />Novedades</button>);
        }else{
            return (<button className="botonPadre mt-2"><FontAwesomeIcon icon={faNewspaper} style={{marginRight: 8, marginLeft: 8}} />Novedades</button>);
        }
    }

    const logout = function(){
        localStorage.removeItem("user-token");
        localStorage.removeItem("user-nombre");
        localStorage.removeItem("user-apellido");
        localStorage.removeItem("user-id");
        window.location.href = "/";
    }

    return (
        <div>
            <img className="w-100 mt-3" style={{marginLeft: -12}} src="https://formacion.secdevstest.com.ar/img/logo.png"/>
            <Link to={`/unidades/`}>
                {btnUnidades()}
            </Link>

            <Link to={`/usuarios/`}>
                {btnUsuarios()}
            </Link>

            <Link to={`/novedades/`}>
                {btnNovedades()}
            </Link>

            <button className="botonSalir" href="#" onClick={logout}><FontAwesomeIcon icon={faSignOutAlt} style={{marginRight: 10, marginLeft: 10}} />Salir</button>
        </div>
    )
}