import React, {useEffect} from 'react'
import {Form, Button, Container, Row, Col} from "react-bootstrap";
import {crearUnidad, obtenerEjes, obtenerSecciones, obtenerMarchas, obtenerUnidad, put} from '../../controlador/index.controller';
import convertirABase64 from '../tools/convertirABase64';
import ViewImages from '../tools/imageViewer';
import imageToBase64 from 'image-to-base64/browser';

export default function CrearUnidad(){
    const [unidadData, setUnidadData] = React.useState('');
    const filePath = "https://formacion.secdevstest.com.ar/data";

    //Descripcion de la unidad
    const [unidadNombre, setUnidadNombre] = React.useState('');
    const [unidadDescripcion, setUnidadDescripcion] = React.useState('');
    const [unidadEtiquetas, setUnidadEtiquetas] = React.useState('');
    const [unidadImagen, setUnidadImagen] = React.useState('');

    //Ejes
    const [ejes = [], setEjes] = React.useState();
    const [ejesSeleccionados = [], setEjesSeleccionados] = React.useState();

    //Secciones
    const [secciones = [], setSecciones] = React.useState();
    const [seccionesSeleccionadas = [], setSeccionesSeleccionadas] = React.useState();

    //Magisterio del Fundador
    //Consigna
    const [magisterioConsignaTitulo, setMagisterioConsignaTitulo] = React.useState('');
    const [magisterioConsignaExplicacion, setMagisterioConsignaExplicacion] = React.useState('');

    //Reflexion
    const [magisterioReflexion, setMagisterioReflexion] = React.useState('');

    //Profundización
    const [magisterioProfundizacionTexto, setMagisterioProfundizacionTexto] = React.useState('');
    const [magisterioProfundizacionEnlace, setMagisterioProfundizacionEnlace] = React.useState('');

    //Poesía
    const [magisterioPoesiaTitulo, setMagisterioPoesiaTitulo] = React.useState('');
    const [magisterioPoesiaTexto, setMagisterioPoesiaTexto] = React.useState('');

    //Elementos simbolicos    
    //Marcha
    const [marchas = [], setMarchas] = React.useState();
    const [simbolicosMarchaExplicacion, setSimbolicosMarchaExplicacion] = React.useState('');
    const [simbolicosMarcha, setSimbolicosMarcha] = React.useState('');

    //Imagenes
    const [simbolicosImagenesTexto, setSimbolicosImagenesTexto] = React.useState('');

    //Obra de arte
    const [simbolicosArteImagenes = [], setSimbolicosArteImagenes] = React.useState();
    const [simbolicosArteTitulo, setSimbolicosArteTitulo] = React.useState('');
    const [simbolicosArteAutor, setSimbolicosArteAutor] = React.useState('');
    const [simbolicosArteFecha, setSimbolicosArteFecha] = React.useState('');
    const [simbolicosArteExplicacion, setSimbolicosArteExplicacion] = React.useState('');

    //Recursos
    //Infografia
    const [recursosInfografia = [], setRecursosInfografia] = React.useState();

    //Video
    const [recursosVideo, setRecursosVideo] = React.useState('');
    const [recursosVideoTitulo, setRecursosVideoTitulo] = React.useState('');
    const [recursosVideoExplicacion, setRecursosVideoExplicacion] = React.useState('');

    //Pelicula
    const [recursosPeliculaTitulo, setRecursosPeliculaTitulo] = React.useState('');
    const [recursosPeliculaDescripcion, setRecursosPeliculaDescripcion] = React.useState('');

    //Cancion
    const [recursosCanciones = [], setRecursosCanciones] = React.useState();

    //Actividad
    const [recursosActividadTitulo, setRecursosActividadTitulo] = React.useState('');
    const [recursosActividadExplicacion, setRecursosActividadExplicacion] = React.useState('');
    const [recursosActividadImagen, setRecursosActividadImagen] = React.useState('');

    //Espiritualidad
    //Espiritualidad Dominicana
    const [espiritualidadDominicanaTexto, setEspiritualidadDominicanaTexto] = React.useState('');
    const [espiritualidadDominicanaEnlaces = [], setEspiritualidadDominicanaEnlaces] = React.useState();
    const [espiritualidadDominicanaImagenes = [], setEspiritualidadDominicanaImagenes] = React.useState();

    //Santo
    const [espiritualidadSantoSanto, setEspiritualidadSantoSanto] = React.useState('');
    const [espiritualidadSantoTexto, setEspiritualidadSantoTexto] = React.useState('');
    const [espiritualidadSantoEnlaces = [], setEspiritualidadSantoEnlaces] = React.useState();
    const [espiritualidadSantoImagenes = [], setEspiritualidadSantoImagenes] = React.useState();

    //Oracion
    const [espiritualidadOracionTitulo, setEspiritualidadOracionTitulo] = React.useState('');
    const [espiritualidadOracionSubtitulo, setEspiritualidadOracionSubtitulo] = React.useState('');
    const [espiritualidadOracionOracion, setEspiritualidadOracionOracion] = React.useState('');

    //Salmo
    const [espiritualidadSalmoTitulo, setEspiritualidadSalmoTitulo] = React.useState('');
    const [espiritualidadSalmoCita, setEspiritualidadSalmoCita] = React.useState('');
    const [espiritualidadSalmoSalmo, setEspiritualidadSalmoSalmo] = React.useState('');
    const [espiritualidadSalmoYouTube, setEspiritualidadSalmoYouTube] = React.useState('');

    //Reflexion
    const [reflexion, setReflexion] = React.useState('');

    //Bibliografia
    const [bibliografia = [], setBibliografia] = React.useState();

    useEffect(() => {
        window.scrollTo(0, 0);
        if(isEdit()){
            document.title = "Unit - Unidades";
            obtenerDatos();
        }else{
            document.title = "Unit - Unidades";
            ejesExistentes();
            seccionesExistentes();
            marchasExistentes();
        }
        
    }, []);

    const isEdit = () => {
        return window.location.search.includes("unidad=");
    };

    const handleUnidadNombre = (event) => {
        setUnidadNombre(event.target.value);
    };

    const handleUnidadDescripcion = (event) => {
        setUnidadDescripcion(event.target.value);
    };

    const handleUnidadEtiquetas = (event) => {
        setUnidadEtiquetas(event.target.value);
    };

    const handleUnidadImagen = async (event) => {
        const file = event.target.files[0]
        const base64 = await convertirABase64(file)
        setUnidadImagen(base64);

        let imgs = [];
        imgs.push(base64);
        document.getElementById("container_unidad_imagen").innerHTML = ViewImages(imgs);
    }     
 
    const handleEjes = (event) => {
        const idCompleto = event.target.id;
        const id = idCompleto.replace("eje", "");
        let aux1 = ejesSeleccionados;

        if(event.target.checked){
            //Hay que agregar el eje
            aux1.push(id);
            setEjesSeleccionados(aux1);
        }else{
            //Hay que eliminar el eje
            for(var i = 0; i < aux1.length; i++){ 
                if(aux1[i] === id){ 
                    aux1.splice(i, 1); 
                }
            }

            setEjesSeleccionados(aux1);
        }
    };

    const handleSecciones = (event) => {
        const idCompleto = event.target.id;
        const id = idCompleto.replace("seccion", "");
        let aux1 = seccionesSeleccionadas;

        if(event.target.checked){
            //Hay que agregar el eje
            aux1.push(id);
            setSeccionesSeleccionadas(aux1);
        }else{
            //Hay que eliminar el eje
            for(var i = 0; i < aux1.length; i++){ 
                if(aux1[i] === id){ 
                    aux1.splice(i, 1); 
                }
            }

            setSeccionesSeleccionadas(aux1);
        }

        console.log(seccionesSeleccionadas);
    };


    const handleMagisterioReflexion = (event) => {
        setMagisterioReflexion(event.target.value);
    };

    const handleMagisterioProfundizacionTexto = (event) => {
        setMagisterioProfundizacionTexto(event.target.value);
    };

    const handleMagisterioProfundizacionEnlace = (event) => {
        setMagisterioProfundizacionEnlace(event.target.value);
    };

    const handleMagisterioConsignaTitulo = (event) => {
        setMagisterioConsignaTitulo(event.target.value);
    };

    const handleMagisterioConsignaExplicacion = (event) => {
        setMagisterioConsignaExplicacion(event.target.value);
    };

    const handleMagisterioPoesiaTitulo = (event) => {
        setMagisterioPoesiaTitulo(event.target.value);
    };

    const handleMagisterioPoesiaTexto = (event) => {
        setMagisterioPoesiaTexto(event.target.value);
    };

    const callbackSimbolicosMarchas = (event) => {
        setSimbolicosMarcha(event.target.value);
    };

    const handleSimbolicosMarchaExplicacion = (event) => {
        setSimbolicosMarchaExplicacion(event.target.value);
    };

    const handleSimbolicosImagenesTexto = (event) => {
        setSimbolicosImagenesTexto(event.target.value);
    };

    const handleSimbolicosArteImagenes = async (event) => {
        let file;
        let base64;
        let imgs = [];
        for(let i = 0; i < event.target.files.length; i++){
            file = event.target.files[i];
            base64 = await convertirABase64(file);
            imgs.push(base64);
        }

        setSimbolicosArteImagenes(imgs);
    
        document.getElementById("container_elementos_simbolicos_arte_imagenes").innerHTML = ViewImages(imgs);
    }     

    const handleSimbolicosArteTitulo = (event) => {
        setSimbolicosArteTitulo(event.target.value);
    };

    const handleSimbolicosArteAutor = (event) => {
        setSimbolicosArteAutor(event.target.value);
    };

    const handleSimbolicosArteFecha = (event) => {
        setSimbolicosArteFecha(event.target.value);
    };

    const handleSimbolicosArteExplicacion = (event) => {
        setSimbolicosArteExplicacion(event.target.value);
    };

    const handleRecursosInfografia = async (event) => {
        let file;
        let base64;
        let imgs = [];
        for(let i = 0; i < event.target.files.length; i++){
            file = event.target.files[i];
            base64 = await convertirABase64(file);
            imgs.push(base64);
        }
        setRecursosInfografia(imgs);

        document.getElementById("container_recursos_infografia_imagenes").innerHTML = ViewImages(imgs);
    }     

    const handleRecursosVideo = (event) => {
        setRecursosVideo(event.target.value);
    };

    const handleRecursosVideoTitulo = (event) => {
        setRecursosVideoTitulo(event.target.value);
    };

    const handleRecursosVideoExplicacion = (event) => {
        setRecursosVideoExplicacion(event.target.value);
    };

    const handleRecursosPeliculaTitulo = (event) => {
        setRecursosPeliculaTitulo(event.target.value);
    };

    const handleRecursosPeliculaDescripcion = (event) => {
        setRecursosPeliculaDescripcion(event.target.value);
    };

    const handleRecursosCanciones = (event) => {
        const cti1 = document.getElementById('recursos_cancion_titulo_1').value;
        const cex1 = document.getElementById('recursos_cancion_explicacion_1').value;
        const cey1 = document.getElementById('recursos_cancion_youtube_1').value;

        const cti2 = document.getElementById('recursos_cancion_titulo_2').value;
        const cex2 = document.getElementById('recursos_cancion_explicacion_2').value;
        const cey2 = document.getElementById('recursos_cancion_youtube_2').value;

        const cti3 = document.getElementById('recursos_cancion_titulo_3').value;
        const cex3 = document.getElementById('recursos_cancion_explicacion_3').value;
        const cey3 = document.getElementById('recursos_cancion_youtube_3').value;

        let canciones = [];

        if(cti1.length > 0 && cex1.length > 0 && cey1.length > 0){
            canciones.push({
                titulo: cti1,
                explicacion: cex1,
                youtube: cey1
            });
        }

        if(cti2.length > 0 && cex2.length > 0 && cey2.length > 0){
            canciones.push({
                titulo: cti2,
                explicacion: cex2,
                youtube: cey2
            });
        }

        if(cti3.length > 0 && cex3.length > 0 && cey3.length > 0){
            canciones.push({
                titulo: cti3,
                explicacion: cex3,
                youtube: cey3
            });
        }  

        setRecursosCanciones(canciones);
    };

    const handleRecursosActividadTitulo = (event) => {
        setRecursosActividadTitulo(event.target.value);
    };

    const handleRecursosActividadExplicacion = (event) => {
        setRecursosActividadExplicacion(event.target.value);
    };

    const handleRecursosActividadImagen = async (event) => {
        const file = event.target.files[0];
        const base64 = await convertirABase64(file);
        setRecursosActividadImagen(base64);

        let imgs = [];
        imgs.push(base64);
        document.getElementById("container_recursos_actividad_imagenes").innerHTML = ViewImages(imgs);
    };

    const handleEspiritualidadDominicanaTexto = (event) => {
        setEspiritualidadDominicanaTexto(event.target.value);
    };

    const handleEspiritualidadDominicanaEnlaces = (event) => {
        const t1 = document.getElementById('espiritualidad_dominicana_texto_1').value;
        const t2 = document.getElementById('espiritualidad_dominicana_texto_2').value;
        const t3 = document.getElementById('espiritualidad_dominicana_texto_3').value;
        const t4 = document.getElementById('espiritualidad_dominicana_texto_4').value;
        const t5 = document.getElementById('espiritualidad_dominicana_texto_5').value;
    
        const e1 = document.getElementById('espiritualidad_dominicana_enlace_1').value;
        const e2 = document.getElementById('espiritualidad_dominicana_enlace_2').value;
        const e3 = document.getElementById('espiritualidad_dominicana_enlace_3').value;
        const e4 = document.getElementById('espiritualidad_dominicana_enlace_4').value;
        const e5 = document.getElementById('espiritualidad_dominicana_enlace_5').value;
       
        let enlaces = [];

        if(t1.length > 5){enlaces.push({texto: t1, enlace: e1})}
        if(t2.length > 5){enlaces.push({texto: t2, enlace: e2})}
        if(t3.length > 5){enlaces.push({texto: t3, enlace: e3})}
        if(t4.length > 5){enlaces.push({texto: t4, enlace: e4})}
        if(t5.length > 5){enlaces.push({texto: t5, enlace: e5})}

        setEspiritualidadDominicanaEnlaces(enlaces);
    };

    const handleEspiritualidadDominicanaImagenes = async (event) => {
        let file;
        let base64;
        let imgs = [];
        for(let i = 0; i < event.target.files.length; i++){
            file = event.target.files[i];
            base64 = await convertirABase64(file);
            imgs.push(base64);
        }
        setEspiritualidadDominicanaImagenes(imgs);

        document.getElementById("container_espiritualidad_dominicana_imagenes").innerHTML = ViewImages(imgs);
    }    

    const handleEspiritualidadSantoSanto = (event) => {
        setEspiritualidadSantoSanto(event.target.value);
    };

    const handleEspiritualidadSantoTexto = (event) => {
        setEspiritualidadSantoTexto(event.target.value);
    };

    const handleEspiritualidadSantoImagenes = async (event) => {
        let file;
        let base64;
        let imgs = [];
        for(let i = 0; i < event.target.files.length; i++){
            file = event.target.files[i];
            base64 = await convertirABase64(file);
            imgs.push(base64);
        }
        setEspiritualidadSantoImagenes(imgs);

        document.getElementById("container_espiritualidad_santo_imagenes").innerHTML = ViewImages(imgs);
    }     

    const handleEspiritualidadSantoEnlaces = (event) => {
        const t1 = document.getElementById('espiritualidad_santo_texto_1').value;
        const t2 = document.getElementById('espiritualidad_santo_texto_2').value;
        const t3 = document.getElementById('espiritualidad_santo_texto_3').value;
    
        const e1 = document.getElementById('espiritualidad_santo_enlace_1').value;
        const e2 = document.getElementById('espiritualidad_santo_enlace_2').value;
        const e3 = document.getElementById('espiritualidad_santo_enlace_3').value;
       
        let enlaces = [];

        if(t1.length > 5){enlaces.push({texto: t1, enlace: e1})}
        if(t2.length > 5){enlaces.push({texto: t2, enlace: e2})}
        if(t3.length > 5){enlaces.push({texto: t3, enlace: e3})}
        
        setEspiritualidadSantoEnlaces(enlaces);
    };

    const handleEspiritualidadOracionTitulo = (event) => {
        setEspiritualidadOracionTitulo(event.target.value);
    };

    const handleEspiritualidadOracionSubtitulo = (event) => {
        setEspiritualidadOracionSubtitulo(event.target.value);
    };

    const handleEspiritualidadOracionOracion = (event) => {
        setEspiritualidadOracionOracion(event.target.value);
    };

    const handleEspiritualidadSalmoTitulo = (event) => {
        setEspiritualidadSalmoTitulo(event.target.value);
    };

    const handleEspiritualidadSalmoCita = (event) => {
        setEspiritualidadSalmoCita(event.target.value);
    };

    const handleEspiritualidadSalmoSalmo = (event) => {
        setEspiritualidadSalmoSalmo(event.target.value);
    };

    const handleEspiritualidadSalmoYouTube = (event) => {
        setEspiritualidadSalmoYouTube(event.target.value);
    };

    const handleReflexion = (event) => {
        setReflexion(event.target.value);
    };

    const handleBibliografia = (event) => {
        const bt1 = document.getElementById('bibliografia_texto_1').value;
        const bt2 = document.getElementById('bibliografia_texto_2').value;
        const bt3 = document.getElementById('bibliografia_texto_3').value;
        const bt4 = document.getElementById('bibliografia_texto_4').value;
        const bt5 = document.getElementById('bibliografia_texto_5').value;
        const bt6 = document.getElementById('bibliografia_texto_6').value;
        const bt7 = document.getElementById('bibliografia_texto_7').value;
        const bt8 = document.getElementById('bibliografia_texto_8').value;
        const bt9 = document.getElementById('bibliografia_texto_9').value;
        const bt10 = document.getElementById('bibliografia_texto_10').value;

        const be1 = document.getElementById('bibliografia_enlace_1').value;
        const be2 = document.getElementById('bibliografia_enlace_2').value;
        const be3 = document.getElementById('bibliografia_enlace_3').value;
        const be4 = document.getElementById('bibliografia_enlace_4').value;
        const be5 = document.getElementById('bibliografia_enlace_5').value;
        const be6 = document.getElementById('bibliografia_enlace_6').value;
        const be7 = document.getElementById('bibliografia_enlace_7').value;
        const be8 = document.getElementById('bibliografia_enlace_8').value;
        const be9 = document.getElementById('bibliografia_enlace_9').value;
        const be10 = document.getElementById('bibliografia_enlace_10').value;

        let bibliografias = [];

        if(bt1.length > 5){bibliografias.push({texto: bt1, enlace: be1})}
        if(bt2.length > 5){bibliografias.push({texto: bt2, enlace: be2})}
        if(bt3.length > 5){bibliografias.push({texto: bt3, enlace: be3})}
        if(bt4.length > 5){bibliografias.push({texto: bt4, enlace: be4})}
        if(bt5.length > 5){bibliografias.push({texto: bt5, enlace: be5})}
        if(bt6.length > 5){bibliografias.push({texto: bt6, enlace: be6})}
        if(bt7.length > 5){bibliografias.push({texto: bt7, enlace: be7})}
        if(bt8.length > 5){bibliografias.push({texto: bt8, enlace: be8})}
        if(bt9.length > 5){bibliografias.push({texto: bt9, enlace: be9})}
        if(bt10.length > 5){bibliografias.push({texto: bt10, enlace: be10})}
        
        setBibliografia(bibliografias);
    };

    const ejesExistentes = async function(){
        const token = localStorage.getItem("user-token");
        const getEjes = await obtenerEjes(token);
        setEjes(getEjes);
    }

    const seccionesExistentes = async function(){
        const token = localStorage.getItem("user-token");
        const getSecciones = await obtenerSecciones(token);
        setSecciones(getSecciones.data);
    }

    const marchasExistentes = async function(){
        const token = localStorage.getItem("user-token");
        const getMarchas = await obtenerMarchas(token);
        setMarchas(getMarchas.data);
    }

    const crear = async function(){
        let unidad = {
            nombre: unidadNombre,
            descripcion: unidadDescripcion,
            imagen: unidadImagen,
            etiquetas: unidadEtiquetas,
            ejes: ejesSeleccionados,
            secciones: seccionesSeleccionadas,
            contenido: { }
        };

        //Magisterio del fundador
        //Consigna
        if(magisterioConsignaTitulo.length > 0 || magisterioConsignaExplicacion.length > 0){
            unidad.contenido.magisterioConsigna = {
                consigna: magisterioConsignaTitulo,
                explicacion: magisterioConsignaExplicacion
            }
        }

        //Reflexion
        if(magisterioReflexion.length > 0){
            unidad.contenido.magisterioReflexion = {
                texto: magisterioReflexion
            }
        }

        //Profundizacion
        if(magisterioProfundizacionTexto.length > 0){
            unidad.contenido.magisterioProfundizacion = {
                texto: magisterioProfundizacionTexto,
                enlace: magisterioProfundizacionEnlace
            }
        }

        //Poesia
        if(magisterioPoesiaTexto.length > 0){
            unidad.contenido.magisterioPoesia = {
                titulo: magisterioPoesiaTitulo,
                texto: magisterioPoesiaTexto
            }
        }

        //Elementos simbolicos
        //Marcha
        if(simbolicosMarcha.length > 0 && simbolicosMarcha !== "0"){
            unidad.contenido.elementosSimbolicosMarcha = {
                marcha_id: simbolicosMarcha,
                explicacion: simbolicosMarchaExplicacion
            }
        }

        //Imagenes
        if(simbolicosImagenesTexto.length > 0){
            unidad.contenido.elementosSimbolicosImagenes = {
                texto: simbolicosImagenesTexto
            };
        }

        //Obra de arte
        if(simbolicosArteTitulo.length > 0 || simbolicosArteAutor.length > 0 || simbolicosArteFecha.length > 0 || simbolicosArteExplicacion.length > 0){
            unidad.contenido.elementosSimbolicosArte = {
                titulo: simbolicosArteTitulo,
                autor: simbolicosArteAutor,
                fecha: simbolicosArteFecha,
                explicacion: simbolicosArteExplicacion,
                imagenes: simbolicosArteImagenes
            }
        }

        //Recursos
        //Infografia
        if(recursosInfografia.length > 0){
            unidad.contenido.recursosInfografia = {
                imagenes: recursosInfografia
            };
        }

        //Video
        if(recursosVideo.length > 0){
            unidad.contenido.recursosVideo = {
                video: recursosVideo,
                titulo: recursosVideoTitulo,
                explicacion: recursosVideoExplicacion
            };
        }

        //Pelicula
        if(recursosPeliculaTitulo.length > 0 || recursosPeliculaDescripcion.length > 0){
            unidad.contenido.recursosPelicula = {
                titulo: recursosPeliculaTitulo,
                descripcion: recursosPeliculaDescripcion
            };
        }

        //Canciones
        if(recursosCanciones.length > 0){
            unidad.contenido.recursosCancion = recursosCanciones;
        }

        //Actividad
        if(recursosActividadTitulo.length > 0 || recursosActividadExplicacion.length > 0){
            unidad.contenido.recursosActividad = {
                titulo: recursosActividadTitulo,
                explicacion: recursosActividadExplicacion,
                imagen: recursosActividadImagen
            };
        }

        //Espiritualidad
        //Espiritualidad Dominicana
        if(espiritualidadDominicanaTexto.length > 0 || espiritualidadDominicanaImagenes.length > 0 || espiritualidadDominicanaEnlaces.length > 0){
            unidad.contenido.espiritualidadDominicana = {
                texto: espiritualidadDominicanaTexto,
                imagenes: espiritualidadDominicanaImagenes,
                enlaces: espiritualidadDominicanaEnlaces
            }
        }

        //Santo
        if(espiritualidadSantoSanto.length > 0 || espiritualidadSantoTexto.length > 0){
            unidad.contenido.espiritualidadSanto = {
                santo: espiritualidadSantoSanto,
                texto: espiritualidadSantoTexto,
                enlaces: espiritualidadSantoEnlaces,
                imagenes: espiritualidadSantoImagenes
            }
        }

        //Oracion
        if(espiritualidadOracionTitulo.length > 0 || espiritualidadOracionSubtitulo.length > 0 || espiritualidadOracionOracion.length > 0){
            unidad.contenido.espiritualidadOracion = {
                titulo: espiritualidadOracionTitulo,
                subtitulo: espiritualidadOracionSubtitulo,
                texto: espiritualidadOracionOracion
            }
        }

        //Salmo
        if(espiritualidadSalmoTitulo.length > 0 || espiritualidadSalmoCita.length > 0 || espiritualidadSalmoSalmo.length > 0){
            unidad.contenido.espiritualidadSalmo = {
                titulo: espiritualidadSalmoTitulo,
                cita: espiritualidadSalmoCita,
                texto: espiritualidadSalmoSalmo
            }
        }

        //Reflexion
        if(reflexion.length > 0){
            unidad.contenido.reflexion = reflexion;
        }

        //Bibliografia
        if(bibliografia.length > 0){
            unidad.contenido.bibliografia = bibliografia;
        }

        if(validarCampos()){
            const token = localStorage.getItem("user-token");
            let postUnidad = await crearUnidad(token, unidad);
    
            if(postUnidad.status === 201){
                alert("Unidad creada correctamente");
                window.location.reload();
            }else{
                alert("Error al crear la unidad. " + postUnidad.data + " " + postUnidad.message);
            }
        }else{
            alert("Faltan campos obligatorios");
        }
    };

    const validarCampos = function(){
        if(unidadNombre.length === 0){return false}
        if(unidadDescripcion.length === 0){return false}
        if(unidadEtiquetas.length === 0){return false}
        if(!isEdit()){
            if(unidadImagen.length === 0){return false}
        }
        if(ejesSeleccionados.length === 0){return false}
        if(seccionesSeleccionadas.length === 0){return false}

        return true;
    }

    const showButton = () => {
        if(isEdit()){
            return (
                <Button variant="success" href={"#"} onClick={edit}>Modificar unidad</Button>
            );
        }else{
            return (
                <Button variant="success" href={"#"} onClick={crear}>Guardar unidad</Button>
            );
        }
    }

    //-----------------------------
    //-----  MODIFICAR UNIDAD -----
    //-----------------------------

    const obtenerDatos = async () => {
        const token = localStorage.getItem("user-token");
        const unidadId = window.location.search.replace('?', '').split('&')[0].replace('unidad=', '');

        const getEjes = await obtenerEjes(token);
        setEjes(getEjes);

        const getSecciones = await obtenerSecciones(token);
        setSecciones(getSecciones.data);

        const getMarchas = await obtenerMarchas(token);
        setMarchas(getMarchas.data);

        const getUnidad = await obtenerUnidad(token, unidadId);
        const data = getUnidad;

        try{
            document.getElementById('unidad_nombre').value = data.nombre;
            setUnidadNombre(data.nombre);
        }catch(e){}

        try{
            document.getElementById('unidad_descripcion').value = data.descripcion;
            setUnidadDescripcion(data.descripcion);
        }catch(e){}

        try{
            document.getElementById('unidad_etiquetas').value = data.etiquetas;
            setUnidadEtiquetas(data.etiquetas);
        }catch(e){}

        try{
            let imgs = [];
            imgs.push(filePath + data.imagen.uri);
            document.getElementById('container_unidad_imagen').innerHTML = ViewImages(imgs);
            
            setUnidadImagen(filePath + data.imagen.uri);
        }catch(e){}

        try{
            let ejes = [];
            for(let i = 0; i < data.unidades_ejes.length; i++){
                document.getElementById('eje' + data.unidades_ejes[i].ejeId).checked = true;
                ejes.push(data.unidades_ejes[i].ejeId + "");
            }
            setEjesSeleccionados(ejes);
        }catch(e){}

        try{
            let secciones = [];
            for(let i = 0; i < data.unidades_secciones.length; i++){
                document.getElementById('seccion' + data.unidades_secciones[i].seccionId).checked = true;
                secciones.push(data.unidades_secciones[i].seccionId + "");
            }
            setSeccionesSeleccionadas(secciones);
        }catch(e){}

        //Magisterio del Fundador
        try{
            document.getElementById('magisterio_consigna_consigna').value = data.unidad_contenido.magisterio_consigna.consigna;
            setMagisterioConsignaTitulo(data.unidad_contenido.magisterio_consigna.consigna);
        }catch(e){}

        try{
            document.getElementById('magisterio_consigna_explicacion').value = data.unidad_contenido.magisterio_consigna.explicacion;
            setMagisterioConsignaExplicacion(data.unidad_contenido.magisterio_consigna.explicacion);
        }catch(e){}

        try{
            document.getElementById('magisterio_reflexion').value = data.unidad_contenido.magisterio_reflexion.texto;
            setMagisterioReflexion(data.unidad_contenido.magisterio_reflexion.texto);
        }catch(e){}

        try{
            document.getElementById('magisterio_profundizacion').value = data.unidad_contenido.magisterio_profundizacion.texto;
            setMagisterioProfundizacionTexto(data.unidad_contenido.magisterio_profundizacion.texto);
        }catch(e){}

        try{
            document.getElementById('magisterio_profundizacion_enlace').value = data.unidad_contenido.magisterio_profundizacion.enlace;
            setMagisterioProfundizacionEnlace(data.unidad_contenido.magisterio_profundizacion.enlace);
        }catch(e){}

        try{
            document.getElementById('magisterio_poesia_titulo').value = data.unidad_contenido.magisterio_poesia.titulo;
            setMagisterioPoesiaTitulo(data.unidad_contenido.magisterio_poesia.titulo);
        }catch(e){}

        try{
            document.getElementById('magisterio_poesia_texto').value = data.unidad_contenido.magisterio_poesia.texto;
            setMagisterioPoesiaTexto(data.unidad_contenido.magisterio_poesia.texto);
        }catch(e){}

        //Elementos Simbolicos

        try{
            document.getElementById('elementos_simbolicos_marcha').value = data.unidad_contenido.elementos_simbolicos_marcha.marchaId;
            setSimbolicosMarcha(data.unidad_contenido.elementos_simbolicos_marcha.marchaId);
        }catch(e){}

        try{
            document.getElementById('elementos_simbolicos_marcha_explicacion').value = data.unidad_contenido.elementos_simbolicos_marcha.explicacion;
            setSimbolicosMarchaExplicacion(data.unidad_contenido.elementos_simbolicos_marcha.explicacion);
        }catch(e){}

        try{
            document.getElementById('elementos_simbolicos_imagen_texto').value = data.unidad_contenido.elementos_simbolicos_imagen.texto;
            setSimbolicosImagenesTexto(data.unidad_contenido.elementos_simbolicos_imagen.texto);
        }catch(e){}

        try{
            let imgs = [];
            for(let i of data.unidad_contenido.elementos_simbolicos_arte.imgs_elementos_simbolicos_artes){
                imgs.push(filePath + i.imagen.uri);
            }
            document.getElementById('container_elementos_simbolicos_arte_imagenes').innerHTML = ViewImages(imgs);

            setSimbolicosArteImagenes(imgs);
        }catch(e){}

        try{
            document.getElementById('elementos_simbolicos_arte_titulo').value = data.unidad_contenido.elementos_simbolicos_arte.titulo;
            setSimbolicosArteTitulo(data.unidad_contenido.elementos_simbolicos_arte.titulo);
        }catch(e){}

        try{
            document.getElementById('elementos_simbolicos_arte_autor').value = data.unidad_contenido.elementos_simbolicos_arte.autor;
            setSimbolicosArteAutor(data.unidad_contenido.elementos_simbolicos_arte.autor);
        }catch(e){}

        try{
            document.getElementById('elementos_simbolicos_arte_fecha').value = data.unidad_contenido.elementos_simbolicos_arte.fecha;
            setSimbolicosArteFecha(data.unidad_contenido.elementos_simbolicos_arte.fecha);
        }catch(e){}

        try{
            document.getElementById('elementos_simbolicos_arte_explicacion').value = data.unidad_contenido.elementos_simbolicos_arte.explicacion;
            setSimbolicosArteExplicacion(data.unidad_contenido.elementos_simbolicos_arte.explicacion);
        }catch(e){}

        //Recursos
        try{
            let imgs = [];
            for(let i of data.unidad_contenido.recursos_infografia.imgs_recursos_infografia){
                imgs.push(filePath + i.imagen.uri);
            }
            document.getElementById('container_recursos_infografia_imagenes').innerHTML = ViewImages(imgs);

            setRecursosInfografia(imgs);
        }catch(e){}

        try{
            document.getElementById('recursos_video_video').value = data.unidad_contenido.recursos_video.video_uri;
            setRecursosVideo(data.unidad_contenido.recursos_video.video_uri);
        }catch(e){}

        try{
            document.getElementById('recursos_video_titulo').value = data.unidad_contenido.recursos_video.titulo;
            setRecursosVideoTitulo(data.unidad_contenido.recursos_video.titulo);
        }catch(e){}

        try{
            document.getElementById('recursos_video_explicacion').value = data.unidad_contenido.recursos_video.explicacion;
            setRecursosVideoExplicacion(data.unidad_contenido.recursos_video.explicacion);
        }catch(e){}

        try{
            document.getElementById('recursos_pelicula_titulo').value = data.unidad_contenido.recursos_pelicula.titulo;
            setRecursosPeliculaTitulo(data.unidad_contenido.recursos_pelicula.titulo);
        }catch(e){}

        try{
            document.getElementById('recursos_pelicula_descripcion').value = data.unidad_contenido.recursos_pelicula.descripcion;
            setRecursosPeliculaDescripcion(data.unidad_contenido.recursos_pelicula.descripcion);
        }catch(e){}

        let canciones = [];

        try{
            document.getElementById('recursos_cancion_titulo_1').value = data.unidad_contenido.recursos_cancions[0].titulo;
            document.getElementById('recursos_cancion_explicacion_1').value = data.unidad_contenido.recursos_cancions[0].explicacion;
            document.getElementById('recursos_cancion_youtube_1').value = data.unidad_contenido.recursos_cancions[0].youtube;

            canciones.push({
                titulo: data.unidad_contenido.recursos_cancions[0].titulo,
                explicacion: data.unidad_contenido.recursos_cancions[0].explicacion,
                youtube: data.unidad_contenido.recursos_cancions[0].youtube
            });
        }catch(e){}

        try{
            document.getElementById('recursos_cancion_titulo_2').value = data.unidad_contenido.recursos_cancions[1].titulo;
            document.getElementById('recursos_cancion_explicacion_2').value = data.unidad_contenido.recursos_cancions[1].explicacion;
            document.getElementById('recursos_cancion_youtube_2').value = data.unidad_contenido.recursos_cancions[1].youtube;

            canciones.push({
                titulo: data.unidad_contenido.recursos_cancions[1].titulo,
                explicacion: data.unidad_contenido.recursos_cancions[1].explicacion,
                youtube: data.unidad_contenido.recursos_cancions[1].youtube
            });
        }catch(e){}

        try{
            document.getElementById('recursos_cancion_titulo_3').value = data.unidad_contenido.recursos_cancions[2].titulo;
            document.getElementById('recursos_cancion_explicacion_3').value = data.unidad_contenido.recursos_cancions[2].explicacion;
            document.getElementById('recursos_cancion_youtube_3').value = data.unidad_contenido.recursos_cancions[2].youtube;

            canciones.push({
                titulo: data.unidad_contenido.recursos_cancions[2].titulo,
                explicacion: data.unidad_contenido.recursos_cancions[2].explicacion,
                youtube: data.unidad_contenido.recursos_cancions[2].youtube
            });
        }catch(e){}
      
        setRecursosCanciones(canciones);

        try{
            document.getElementById('recursos_actividad_titulo').value = data.unidad_contenido.recursos_actividad.titulo;
            setRecursosActividadTitulo(data.unidad_contenido.recursos_actividad.titulo);
        }catch(e){}

        try{
            document.getElementById('recursos_actividad_explicacion').value = data.unidad_contenido.recursos_actividad.explicacion;
            setRecursosActividadExplicacion(data.unidad_contenido.recursos_actividad.explicacion);
        }catch(e){}

        try{
            let imgs = [];
            imgs.push(filePath + data.unidad_contenido.recursos_actividad.imagen.uri);
            document.getElementById('container_recursos_actividad_imagenes').innerHTML = ViewImages(imgs);

            setRecursosActividadImagen(filePath + data.unidad_contenido.recursos_actividad.imagen.uri);
        }catch(e){}

        //Espiritualidad

        try{
            document.getElementById('espiritualidad_dominicana_texto').value = data.unidad_contenido.espiritualidad_dominicana.texto;
            setEspiritualidadDominicanaTexto(data.unidad_contenido.espiritualidad_dominicana.texto);
        }catch(e){}

        try{
            let imgs = [];
            for(let i of data.unidad_contenido.espiritualidad_dominicana.imgs_espiritualidad_dominicanas){
                imgs.push(filePath + i.imagen.uri);
            }
            document.getElementById('container_espiritualidad_dominicana_imagenes').innerHTML = ViewImages(imgs);

            setEspiritualidadDominicanaImagenes(imgs);
        }catch(e){}

        let espiritualidadDominicanaEnlaces = [];

        try{
            document.getElementById('espiritualidad_dominicana_texto_1').value = data.unidad_contenido.espiritualidad_dominicana.enlaces_espiritualidad_dominicanas[0].texto;
            document.getElementById('espiritualidad_dominicana_enlace_1').value = data.unidad_contenido.espiritualidad_dominicana.enlaces_espiritualidad_dominicanas[0].enlace;

            espiritualidadDominicanaEnlaces.push({
                texto: data.unidad_contenido.espiritualidad_dominicana.enlaces_espiritualidad_dominicanas[0].texto,
                enlace: data.unidad_contenido.espiritualidad_dominicana.enlaces_espiritualidad_dominicanas[0].enlace
            });
        }catch(e){}

        try{
            document.getElementById('espiritualidad_dominicana_texto_2').value = data.unidad_contenido.espiritualidad_dominicana.enlaces_espiritualidad_dominicanas[1].texto;
            document.getElementById('espiritualidad_dominicana_enlace_2').value = data.unidad_contenido.espiritualidad_dominicana.enlaces_espiritualidad_dominicanas[1].enlace;

            espiritualidadDominicanaEnlaces.push({
                texto: data.unidad_contenido.espiritualidad_dominicana.enlaces_espiritualidad_dominicanas[1].texto,
                enlace: data.unidad_contenido.espiritualidad_dominicana.enlaces_espiritualidad_dominicanas[1].enlace
            });
        }catch(e){}

        try{
            document.getElementById('espiritualidad_dominicana_texto_3').value = data.unidad_contenido.espiritualidad_dominicana.enlaces_espiritualidad_dominicanas[2].texto;
            document.getElementById('espiritualidad_dominicana_enlace_3').value = data.unidad_contenido.espiritualidad_dominicana.enlaces_espiritualidad_dominicanas[2].enlace;

            espiritualidadDominicanaEnlaces.push({
                texto: data.unidad_contenido.espiritualidad_dominicana.enlaces_espiritualidad_dominicanas[2].texto,
                enlace: data.unidad_contenido.espiritualidad_dominicana.enlaces_espiritualidad_dominicanas[2].enlace
            });
        }catch(e){}

        try{
            document.getElementById('espiritualidad_dominicana_texto_4').value = data.unidad_contenido.espiritualidad_dominicana.enlaces_espiritualidad_dominicanas[3].texto;
            document.getElementById('espiritualidad_dominicana_enlace_4').value = data.unidad_contenido.espiritualidad_dominicana.enlaces_espiritualidad_dominicanas[3].enlace;

            espiritualidadDominicanaEnlaces.push({
                texto: data.unidad_contenido.espiritualidad_dominicana.enlaces_espiritualidad_dominicanas[3].texto,
                enlace: data.unidad_contenido.espiritualidad_dominicana.enlaces_espiritualidad_dominicanas[3].enlace
            });
        }catch(e){}

        try{
            document.getElementById('espiritualidad_dominicana_texto_5').value = data.unidad_contenido.espiritualidad_dominicana.enlaces_espiritualidad_dominicanas[4].texto;
            document.getElementById('espiritualidad_dominicana_enlace_5').value = data.unidad_contenido.espiritualidad_dominicana.enlaces_espiritualidad_dominicanas[4].enlace;

            espiritualidadDominicanaEnlaces.push({
                texto: data.unidad_contenido.espiritualidad_dominicana.enlaces_espiritualidad_dominicanas[4].texto,
                enlace: data.unidad_contenido.espiritualidad_dominicana.enlaces_espiritualidad_dominicanas[4].enlace
            });
        }catch(e){}

        setEspiritualidadDominicanaEnlaces(espiritualidadDominicanaEnlaces);

        try{
            document.getElementById('espiritualidad_santo').value = data.unidad_contenido.espiritualidad_santo.santo;
            setEspiritualidadSantoSanto(data.unidad_contenido.espiritualidad_santo.santo);
        }catch(e){}

        try{
            document.getElementById('espiritualidad_santo_texto').value = data.unidad_contenido.espiritualidad_santo.texto;
            setEspiritualidadSantoTexto(data.unidad_contenido.espiritualidad_santo.texto);
        }catch(e){}

        try{
            let imgs = [];
            for(let i of data.unidad_contenido.espiritualidad_santo.imgs_espiritualidad_santos){
                imgs.push(filePath + i.imagen.uri);
            }
            document.getElementById('container_espiritualidad_santo_imagenes').innerHTML = ViewImages(imgs);

            setEspiritualidadSantoImagenes(imgs);
        }catch(e){}

        let espiritualidadSantoEnlaces = [];

        try{
            document.getElementById('espiritualidad_santo_texto_1').value = data.unidad_contenido.espiritualidad_santo.enlaces_espiritualidad_santos[0].texto;
            document.getElementById('espiritualidad_santo_enlace_1').value = data.unidad_contenido.espiritualidad_santo.enlaces_espiritualidad_santos[0].enlace;

            espiritualidadSantoEnlaces.push({
                texto: data.unidad_contenido.espiritualidad_santo.enlaces_espiritualidad_santos[0].texto,
                enlace: data.unidad_contenido.espiritualidad_santo.enlaces_espiritualidad_santos[0].enlace
            });
        }catch(e){}

        try{
            document.getElementById('espiritualidad_santo_texto_2').value = data.unidad_contenido.espiritualidad_santo.enlaces_espiritualidad_santos[1].texto;
            document.getElementById('espiritualidad_santo_enlace_2').value = data.unidad_contenido.espiritualidad_santo.enlaces_espiritualidad_santos[1].enlace;

            espiritualidadSantoEnlaces.push({
                texto: data.unidad_contenido.espiritualidad_santo.enlaces_espiritualidad_santos[1].texto,
                enlace: data.unidad_contenido.espiritualidad_santo.enlaces_espiritualidad_santos[1].enlace
            });
        }catch(e){}

        try{
            document.getElementById('espiritualidad_santo_texto_3').value = data.unidad_contenido.espiritualidad_santo.enlaces_espiritualidad_santos[2].texto;
            document.getElementById('espiritualidad_santo_enlace_3').value = data.unidad_contenido.espiritualidad_santo.enlaces_espiritualidad_santos[2].enlace;

            espiritualidadSantoEnlaces.push({
                texto: data.unidad_contenido.espiritualidad_santo.enlaces_espiritualidad_santos[2].texto,
                enlace: data.unidad_contenido.espiritualidad_santo.enlaces_espiritualidad_santos[2].enlace
            });
        }catch(e){}

        setEspiritualidadSantoEnlaces(espiritualidadSantoEnlaces);

        try{
            document.getElementById('espiritualidad_oracion_titulo').value = data.unidad_contenido.espiritualidad_oracion.titulo;
            setEspiritualidadOracionTitulo(data.unidad_contenido.espiritualidad_oracion.titulo);
        }catch(e){}

        try{
            document.getElementById('espiritualidad_oracion_subtitulo').value = data.unidad_contenido.espiritualidad_oracion.subtitulo;
            setEspiritualidadOracionSubtitulo(data.unidad_contenido.espiritualidad_oracion.subtitulo);
        }catch(e){}

        try{
            document.getElementById('espiritualidad_oracion_texto').value = data.unidad_contenido.espiritualidad_oracion.texto;
            setEspiritualidadOracionOracion(data.unidad_contenido.espiritualidad_oracion.texto);
        }catch(e){}

        try{
            document.getElementById('espiritualidad_salmo_titulo').value = data.unidad_contenido.espiritualidad_salmo.titulo;
            setEspiritualidadSalmoTitulo(data.unidad_contenido.espiritualidad_salmo.titulo);
        }catch(e){}

        try{
            document.getElementById('espiritualidad_salmo_cita').value = data.unidad_contenido.espiritualidad_salmo.cita;
            setEspiritualidadSalmoCita(data.unidad_contenido.espiritualidad_salmo.cita);
        }catch(e){}

        try{
            document.getElementById('espiritualidad_salmo_texto').value = data.unidad_contenido.espiritualidad_salmo.texto;
            setEspiritualidadSalmoSalmo(data.unidad_contenido.espiritualidad_salmo.texto);
        }catch(e){}

        try{
            document.getElementById('espiritualidad_salmo_youtube').value = data.unidad_contenido.espiritualidad_salmo.youtube;
            setEspiritualidadSalmoYouTube(data.unidad_contenido.espiritualidad_salmo.youtube);
        }catch(e){}

        //Reflexion
        try{
            document.getElementById('reflexion').value = data.unidad_contenido.reflexion.texto;
            setReflexion(data.unidad_contenido.reflexion.texto);
        }catch(e){}

        //Bibliografia

        let bibliografia = [];

        try{
            document.getElementById('bibliografia_texto_1').value = data.unidad_contenido.bibliografia[0].texto;
            document.getElementById('bibliografia_enlace_1').value = data.unidad_contenido.bibliografia[0].enlace;

            bibliografia.push({
                texto: data.unidad_contenido.bibliografia[0].texto,
                enlace: data.unidad_contenido.bibliografia[0].enlace
            });
        }catch(e){}

        try{
            document.getElementById('bibliografia_texto_2').value = data.unidad_contenido.bibliografia[1].texto;
            document.getElementById('bibliografia_enlace_2').value = data.unidad_contenido.bibliografia[1].enlace;

            bibliografia.push({
                texto: data.unidad_contenido.bibliografia[1].texto,
                enlace: data.unidad_contenido.bibliografia[1].enlace
            });
        }catch(e){}

        try{
            document.getElementById('bibliografia_texto_3').value = data.unidad_contenido.bibliografia[2].texto;
            document.getElementById('bibliografia_enlace_3').value = data.unidad_contenido.bibliografia[2].enlace;

            bibliografia.push({
                texto: data.unidad_contenido.bibliografia[2].texto,
                enlace: data.unidad_contenido.bibliografia[2].enlace
            });
        }catch(e){}

        try{
            document.getElementById('bibliografia_texto_4').value = data.unidad_contenido.bibliografia[3].texto;
            document.getElementById('bibliografia_enlace_4').value = data.unidad_contenido.bibliografia[3].enlace;

            bibliografia.push({
                texto: data.unidad_contenido.bibliografia[3].texto,
                enlace: data.unidad_contenido.bibliografia[3].enlace
            });
        }catch(e){}

        try{
            document.getElementById('bibliografia_texto_5').value = data.unidad_contenido.bibliografia[4].texto;
            document.getElementById('bibliografia_enlace_5').value = data.unidad_contenido.bibliografia[4].enlace;

            bibliografia.push({
                texto: data.unidad_contenido.bibliografia[4].texto,
                enlace: data.unidad_contenido.bibliografia[4].enlace
            });
        }catch(e){}

        try{
            document.getElementById('bibliografia_texto_6').value = data.unidad_contenido.bibliografia[5].texto;
            document.getElementById('bibliografia_enlace_6').value = data.unidad_contenido.bibliografia[5].enlace;

            bibliografia.push({
                texto: data.unidad_contenido.bibliografia[5].texto,
                enlace: data.unidad_contenido.bibliografia[5].enlace
            });
        }catch(e){}

        try{
            document.getElementById('bibliografia_texto_7').value = data.unidad_contenido.bibliografia[6].texto;
            document.getElementById('bibliografia_enlace_7').value = data.unidad_contenido.bibliografia[6].enlace;

            bibliografia.push({
                texto: data.unidad_contenido.bibliografia[6].texto,
                enlace: data.unidad_contenido.bibliografia[6].enlace
            });
        }catch(e){}

        try{
            document.getElementById('bibliografia_texto_8').value = data.unidad_contenido.bibliografia[7].texto;
            document.getElementById('bibliografia_enlace_8').value = data.unidad_contenido.bibliografia[7].enlace;

            bibliografia.push({
                texto: data.unidad_contenido.bibliografia[7].texto,
                enlace: data.unidad_contenido.bibliografia[7].enlace
            });
        }catch(e){}

        try{
            document.getElementById('bibliografia_texto_9').value = data.unidad_contenido.bibliografia[8].texto;
            document.getElementById('bibliografia_enlace_9').value = data.unidad_contenido.bibliografia[8].enlace;

            bibliografia.push({
                texto: data.unidad_contenido.bibliografia[8].texto,
                enlace: data.unidad_contenido.bibliografia[8].enlace
            });
        }catch(e){}

        try{
            document.getElementById('bibliografia_texto_10').value = data.unidad_contenido.bibliografia[9].texto;
            document.getElementById('bibliografia_enlace_10').value = data.unidad_contenido.bibliografia[9].enlace;

            bibliografia.push({
                texto: data.unidad_contenido.bibliografia[9].texto,
                enlace: data.unidad_contenido.bibliografia[9].enlace
            });
        }catch(e){}

        setBibliografia(bibliografia);

        setUnidadData(data);
    }

    const edit = async function(){
        let unidad = {
            nombre: unidadNombre,
            descripcion: unidadDescripcion,
            imagen: unidadImagen,
            etiquetas: unidadEtiquetas,
            ejes: ejesSeleccionados,
            secciones: seccionesSeleccionadas,
            contenido: { }
        };

        //Magisterio del fundador
        //Consigna
        if(magisterioConsignaTitulo.length > 0 || magisterioConsignaExplicacion.length > 0){
            unidad.contenido.magisterioConsigna = {
                consigna: magisterioConsignaTitulo,
                explicacion: magisterioConsignaExplicacion
            }
        }

        //Reflexion
        if(magisterioReflexion.length > 0){
            unidad.contenido.magisterioReflexion = {
                texto: magisterioReflexion
            }
        }

        //Profundizacion
        if(magisterioProfundizacionTexto.length > 0){
            unidad.contenido.magisterioProfundizacion = {
                texto: magisterioProfundizacionTexto,
                enlace: magisterioProfundizacionEnlace
            }
        }

        //Poesia
        if(magisterioPoesiaTexto.length > 0){
            unidad.contenido.magisterioPoesia = {
                titulo: magisterioPoesiaTitulo,
                texto: magisterioPoesiaTexto
            }
        }

        //Elementos simbolicos
        //Marcha
        if(simbolicosMarcha > 0){
            unidad.contenido.elementosSimbolicosMarcha = {
                marcha_id: simbolicosMarcha,
                explicacion: simbolicosMarchaExplicacion
            }
        }

        //Imagenes
        if(simbolicosImagenesTexto.length > 0){
            unidad.contenido.elementosSimbolicosImagenes = {
                texto: simbolicosImagenesTexto
            };
        }

        //Obra de arte
        if(simbolicosArteTitulo.length > 0 || simbolicosArteAutor.length > 0 || simbolicosArteFecha.length > 0 || simbolicosArteExplicacion.length > 0){
            unidad.contenido.elementosSimbolicosArte = {
                titulo: simbolicosArteTitulo,
                autor: simbolicosArteAutor,
                fecha: simbolicosArteFecha,
                explicacion: simbolicosArteExplicacion,
                imagenes: simbolicosArteImagenes
            }
        }

        //Recursos
        //Infografia
        if(recursosInfografia.length > 0){
            unidad.contenido.recursosInfografia = {
                imagenes: recursosInfografia
            };
        }

        //Video
        if(recursosVideo.length > 0){
            unidad.contenido.recursosVideo = {
                video: recursosVideo,
                titulo: recursosVideoTitulo,
                explicacion: recursosVideoExplicacion
            };
        }

        //Pelicula
        if(recursosPeliculaTitulo.length > 0 || recursosPeliculaDescripcion.length > 0){
            unidad.contenido.recursosPelicula = {
                titulo: recursosPeliculaTitulo,
                descripcion: recursosPeliculaDescripcion
            };
        }

        //Canciones
        if(recursosCanciones.length > 0){
            unidad.contenido.recursosCancion = recursosCanciones;
        }

        //Actividad
        if(recursosActividadTitulo.length > 0 || recursosActividadExplicacion.length > 0){
            unidad.contenido.recursosActividad = {
                titulo: recursosActividadTitulo,
                explicacion: recursosActividadExplicacion,
                imagen: recursosActividadImagen
            };
        }

        //Espiritualidad
        //Espiritualidad Dominicana
        if(espiritualidadDominicanaTexto.length > 0 || espiritualidadDominicanaImagenes.length > 0 || espiritualidadDominicanaEnlaces.length > 0){
            unidad.contenido.espiritualidadDominicana = {
                texto: espiritualidadDominicanaTexto,
                imagenes: espiritualidadDominicanaImagenes,
                enlaces: espiritualidadDominicanaEnlaces
            }
        }

        //Santo
        if(espiritualidadSantoSanto.length > 0 || espiritualidadSantoTexto.length > 0){
            unidad.contenido.espiritualidadSanto = {
                santo: espiritualidadSantoSanto,
                texto: espiritualidadSantoTexto,
                enlaces: espiritualidadSantoEnlaces,
                imagenes: espiritualidadSantoImagenes
            }
        }

        //Oracion
        if(espiritualidadOracionTitulo.length > 0 || espiritualidadOracionSubtitulo.length > 0 || espiritualidadOracionOracion.length > 0){
            unidad.contenido.espiritualidadOracion = {
                titulo: espiritualidadOracionTitulo,
                subtitulo: espiritualidadOracionSubtitulo,
                texto: espiritualidadOracionOracion
            }
        }

        //Salmo
        if(espiritualidadSalmoTitulo.length > 0 || espiritualidadSalmoCita.length > 0 || espiritualidadSalmoSalmo.length > 0){
            unidad.contenido.espiritualidadSalmo = {
                titulo: espiritualidadSalmoTitulo,
                cita: espiritualidadSalmoCita,
                texto: espiritualidadSalmoSalmo
            }
        }

        //Reflexion
        if(reflexion.length > 0){
            unidad.contenido.reflexion = reflexion;
        }

        //Bibliografia
        if(bibliografia.length > 0){
            unidad.contenido.bibliografia = bibliografia;
        }

        if(validarCampos()){
            const unidadId = window.location.search.replace('?', '').split('&')[0].replace('unidad=', '');
            const token = localStorage.getItem("user-token");
            let req = await put("api/ejes/unidades/" + unidadId, token, unidad);
    
            if(req.status === 201){
                alert("Unidad modificada correctamente. En caso de haber modificado videos de YouTube, puede tardar hasta 10 minutos en aplicarse todos los cambios.");
                window.location.href = "/unidades/";
            }else{
                const resp = await req.json();
                alert("Error al modificar la unidad. " + resp.data + " " + resp.message);
            }
        }else{
            alert("Faltan campos obligatorios");
        }
    };

    //-----------------------------
    //-----------------------------
    //-----------------------------

    return (
        <div>
            <Container>
                <Row>
                    <Col>
                        <h1 className="mb-4">{isEdit() ? "Modificar" : "Crear"} unidad</h1>
                        <Form>
                            <div className="mb-4" style={{width:"100%", background:"#BFDFC7", textAlign:"center", paddingTop:20, paddingBottom:20}}>
                                <span><h5>Descripción</h5></span>
                            </div>
                            <Form.Group className="mb-3" >
                                <Form.Text className="text-muted">Nombre de la unidad </Form.Text>
                                <Form.Text className="text-danger">Obligatorio</Form.Text>
                                <Form.Control onChange = {(event) => handleUnidadNombre(event)} type="text" id="unidad_nombre" placeholder="" />
                            </Form.Group>

                            <Form.Group className="mb-3" >
                                <Form.Text className="text-muted">Descripción </Form.Text>
                                <Form.Text className="text-danger">Obligatorio</Form.Text>
                                <Form.Control onChange = {(event) => handleUnidadDescripcion(event)} as="textarea" placeholder="" id="unidad_descripcion" rows={5} />
                            </Form.Group>

                            <Form.Group className="mb-3" >
                                <Form.Text className="text-muted">Etiquetas </Form.Text>
                                <Form.Text className="text-danger">Obligatorio</Form.Text>
                                <Form.Control onChange = {(event) => handleUnidadEtiquetas(event)} type="text" id="unidad_etiquetas" placeholder="" />
                                <Form.Text className="text-muted">Deben estar separadas por punto y coma ( ; ). Ejemplo: vida; gracia; pecado</Form.Text>
                            </Form.Group>

                            <Form.Group className="mb-3" >
                                <Form.Text className="text-muted">Imagen </Form.Text>
                                <Form.Text className="text-danger">Obligatorio</Form.Text>
                                <div className="mb-1"></div>
                                <Form.Control type="file" onChange = {(event) => handleUnidadImagen(event)}/>
                                <Form.Text className="text-danger">Formatos admitidos: PNG, JPG y JPEG</Form.Text>
                                <div id="container_unidad_imagen"></div>
                            </Form.Group>

                            <Form.Group className="mb-3" >
                                <Form.Text className="text-muted">Ejes </Form.Text>
                                <Form.Text className="text-danger">Obligatorio</Form.Text>
                                <div className="mb-1"></div>
                                {ejes.map((e) => (
                                    <Form.Check id={"eje" + e.id} onChange = {(event) => handleEjes(event)} type="checkbox" label={e.nombre}/>
                                ))}
                            </Form.Group>

                            <Form.Group className="mb-5" >
                                <Form.Text className="text-muted">Secciones </Form.Text>
                                <Form.Text className="text-danger">Obligatorio</Form.Text>
                                <div className="mb-1"></div>
                                {secciones.map((s) => (
                                    <Form.Check id={"seccion" + s.id} onChange = {(event) => handleSecciones(event)} type="checkbox" label={s.agrupacion + " - " + s.nombre}/>
                                ))}
                            </Form.Group>

                            <div className="mb-4" style={{width:"100%", background:"#9DD9E2", textAlign:"center", paddingTop:20, paddingBottom:20}}>
                                <span className="mb-1"><h5>Magisterio del Fundador</h5></span>
                            </div>
                            <Form.Group className="mb-2" >
                                <Form.Label>Consigna</Form.Label>
                                <br></br>
                                <Form.Text className="text-muted">Consigna</Form.Text>
                                <Form.Control onChange = {(event) => handleMagisterioConsignaTitulo(event)} type="text" id="magisterio_consigna_consigna" placeholder="" />
                            </Form.Group>

                            <Form.Group className="mb-4" >
                                <Form.Text className="text-muted">Explicación</Form.Text>
                                <Form.Control onChange = {(event) => handleMagisterioConsignaExplicacion(event)} as="textarea" id="magisterio_consigna_explicacion" placeholder="" rows={5} />
                            </Form.Group>

                            <Form.Group className="mb-4" >
                                <Form.Label>Reflexión</Form.Label>
                                <br></br>
                                <Form.Text className="text-muted">Texto</Form.Text>
                                <Form.Control onChange = {(event) => handleMagisterioReflexion(event)} as="textarea" placeholder="" id="magisterio_reflexion" rows={5} />
                            </Form.Group>

                            <Form.Group className="mb-2" >
                                <Form.Label>Profundización</Form.Label>
                                <br></br>
                                <Form.Text className="text-muted">Texto</Form.Text>
                                <Form.Control onChange = {(event) => handleMagisterioProfundizacionTexto(event)} as="textarea"id="magisterio_profundizacion" placeholder="" rows={3} />
                            </Form.Group>

                            <Form.Group className="mb-4" >
                                <Form.Text className="text-muted">Enlace</Form.Text>
                                <Form.Control onChange = {(event) => handleMagisterioProfundizacionEnlace(event)} type="text" id="magisterio_profundizacion_enlace" placeholder="" />
                            </Form.Group>

                            <Form.Group className="mb-2" >
                                <Form.Label>Poesía</Form.Label>
                                <br></br>
                                <Form.Text className="text-muted">Título</Form.Text>
                                <Form.Control onChange = {(event) => handleMagisterioPoesiaTitulo(event)} type="text" id="magisterio_poesia_titulo" placeholder="" />
                            </Form.Group>

                            <Form.Group className="mb-5">
                                <Form.Text className="text-muted">Texto</Form.Text>
                                <Form.Control onChange = {(event) => handleMagisterioPoesiaTexto(event)} as="textarea" placeholder="" id="magisterio_poesia_texto" rows={3} />
                            </Form.Group>

                            <div className="mb-4" style={{width:"100%", background:"#F7F4B1", textAlign:"center", paddingTop:20, paddingBottom:20}}>
                                <span><h5>Signos y Símbolos</h5></span>
                            </div>
                        

                            <Form.Group className="mb-2" >
                                <Form.Label>Marcha</Form.Label>
                                <br></br>
                                <Form.Text className="text-muted">Marcha</Form.Text>
                                <Form.Select onChange = {(event) => callbackSimbolicosMarchas(event)} id="elementos_simbolicos_marcha">
                                    <option value="0">Seleccionar marcha...</option>
                                    {marchas.map((m) => (
                                        <option id={"marcha" + m.id} value={m.id}>{m.titulo}</option>
                                    ))}
                                </Form.Select>
                            </Form.Group>

                            <Form.Group className="mb-4" >
                                <Form.Text className="text-muted">Explicación</Form.Text>
                                <Form.Control onChange = {(event) => handleSimbolicosMarchaExplicacion(event)} as="textarea" id="elementos_simbolicos_marcha_explicacion" placeholder="" rows={5} />
                            </Form.Group>

                            <Form.Group className="mb-4" >
                                <Form.Label>Imágenes</Form.Label>
                                <br></br>
                                <Form.Text className="text-muted">Texto</Form.Text>
                                <Form.Control onChange = {(event) => handleSimbolicosImagenesTexto(event)} as="textarea" id="elementos_simbolicos_imagen_texto" placeholder="" rows={5} />
                            </Form.Group>

                            <Form.Group className="mb-2" >
                                <Form.Label>Obra de arte</Form.Label>
                                <br></br>
                                <Form.Text className="text-muted">Imágenes</Form.Text>
                                <Form.Control type="file" multiple onChange = {(event) => handleSimbolicosArteImagenes(event)}/>
                                <Form.Text className="text-danger">Formatos admitidos: PNG, JPG y JPEG</Form.Text>
                                <div id="container_elementos_simbolicos_arte_imagenes"></div>
                            </Form.Group>
                            <Form.Group className="mb-2" >
                                <Form.Text className="text-muted">Título</Form.Text>
                                <Form.Control onChange = {(event) => handleSimbolicosArteTitulo(event)} type="text" id="elementos_simbolicos_arte_titulo" placeholder="" />
                            </Form.Group>
                            <Form.Group className="mb-2" >
                                <Form.Text className="text-muted">Autor</Form.Text>
                                <Form.Control onChange = {(event) => handleSimbolicosArteAutor(event)} type="text" id="elementos_simbolicos_arte_autor" placeholder="" />
                            </Form.Group>
                            <Form.Group className="mb-2" >
                                <Form.Text className="text-muted">Fecha</Form.Text>
                                <Form.Control onChange = {(event) => handleSimbolicosArteFecha(event)} type="text" id="elementos_simbolicos_arte_fecha" placeholder="" />
                            </Form.Group>
                            <Form.Group className="mb-5" >
                                <Form.Text className="text-muted">Explicación</Form.Text>
                                <Form.Control onChange = {(event) => handleSimbolicosArteExplicacion(event)} as="textarea" placeholder="" id="elementos_simbolicos_arte_explicacion" rows={5} />
                            </Form.Group>

                            <div className="mb-4" style={{width:"100%", background:"#FCC4B0", textAlign:"center", paddingTop:20, paddingBottom:20}}>
                                <span><h5>Recursos</h5></span>
                            </div>
                            <Form.Group className="mb-4" >
                                <Form.Label>Infografía</Form.Label>
                                <br></br>
                                <Form.Text className="text-muted">Imágenes</Form.Text>
                                <Form.Control type="file" multiple onChange = {(event) => handleRecursosInfografia(event)}/>
                                <Form.Text className="text-danger">Formatos admitidos: PNG, JPG y JPEG</Form.Text>
                                <div id="container_recursos_infografia_imagenes"></div>
                            </Form.Group>

                            <Form.Group className="mb-2" >
                                <Form.Label>Video</Form.Label>
                                <br></br>
                                <Form.Text className="text-muted">Enlace YouTube</Form.Text>
                                <Form.Control onChange = {(event) => handleRecursosVideo(event)} type="text" id="recursos_video_video" placeholder="" />
                            </Form.Group>

                            <Form.Group className="mb-2" >
                                <Form.Text className="text-muted">Título</Form.Text>
                                <Form.Control onChange = {(event) => handleRecursosVideoTitulo(event)} type="text" id="recursos_video_titulo" placeholder="" />
                            </Form.Group>

                            <Form.Group className="mb-4" >
                                <Form.Text className="text-muted">Explicación</Form.Text>
                                <Form.Control onChange = {(event) => handleRecursosVideoExplicacion(event)} as="textarea" placeholder="" id="recursos_video_explicacion" rows={5} />
                            </Form.Group>

                            <Form.Group className="mb-2" >
                                <Form.Label>Película</Form.Label>
                                <br></br>
                                <Form.Text className="text-muted">Título</Form.Text>
                                <Form.Control onChange = {(event) => handleRecursosPeliculaTitulo(event)} type="text" id="recursos_pelicula_titulo" placeholder="" />
                            </Form.Group>
                            <Form.Group className="mb-4" >
                                <Form.Text className="text-muted">Descripción</Form.Text>
                                <Form.Control onChange = {(event) => handleRecursosPeliculaDescripcion(event)} as="textarea" placeholder="" id="recursos_pelicula_descripcion" rows={5} />
                            </Form.Group>

                            <Form.Group className="mb-2" >
                                <Form.Label>Canciones</Form.Label>
                                <br></br>
                                <Form.Label>Canción 1</Form.Label>
                                <br></br>
                                <Form.Text className="text-muted">Título</Form.Text>
                                <Form.Control onChange = {(event) => handleRecursosCanciones(event)} type="text" id="recursos_cancion_titulo_1" placeholder="" />
                            </Form.Group>
                            <Form.Group className="mb-2" >
                                <Form.Text className="text-muted">Explicación</Form.Text>
                                <Form.Control onChange = {(event) => handleRecursosCanciones(event)} as="textarea" id="recursos_cancion_explicacion_1" rows={5} />
                            </Form.Group>
                            <Form.Group className="mb-4" >
                                <Form.Text className="text-muted">Enlace YouTube</Form.Text>
                                <Form.Control onChange = {(event) => handleRecursosCanciones(event)} type="text" id="recursos_cancion_youtube_1" placeholder="" />
                                <Form.Text className="text-danger">Advertencia: no se mostrará el video en la aplicación, solo el audio</Form.Text>
                            </Form.Group>

                            <Form.Group className="mb-2" >
                                <Form.Label>Canción 2</Form.Label>
                                <br></br>
                                <Form.Text className="text-muted">Título</Form.Text>
                                <Form.Control onChange = {(event) => handleRecursosCanciones(event)} type="text" id="recursos_cancion_titulo_2" placeholder="" />
                            </Form.Group>
                            <Form.Group className="mb-2" >
                                <Form.Text className="text-muted">Explicación</Form.Text>
                                <Form.Control onChange = {(event) => handleRecursosCanciones(event)} as="textarea" id="recursos_cancion_explicacion_2" rows={5} />
                            </Form.Group>
                            <Form.Group className="mb-4" >
                                <Form.Text className="text-muted">Enlace YouTube</Form.Text>
                                <Form.Control onChange = {(event) => handleRecursosCanciones(event)} type="text" id="recursos_cancion_youtube_2" placeholder="" />
                                <Form.Text className="text-danger">Advertencia: no se mostrará el video en la aplicación, solo el audio</Form.Text>
                            </Form.Group>

                            <Form.Group className="mb-2" >
                                <Form.Label>Canción 3</Form.Label>
                                <br></br>
                                <Form.Text className="text-muted">Título</Form.Text>
                                <Form.Control onChange = {(event) => handleRecursosCanciones(event)} type="text" id="recursos_cancion_titulo_3" placeholder="" />
                            </Form.Group>
                            <Form.Group className="mb-2" >
                                <Form.Text className="text-muted">Explicación</Form.Text>
                                <Form.Control onChange = {(event) => handleRecursosCanciones(event)} as="textarea" placeholder="" id="recursos_cancion_explicacion_3" rows={5} />
                            </Form.Group>
                            <Form.Group className="mb-4" >
                                <Form.Text className="text-muted">Enlace YouTube</Form.Text>
                                <Form.Control onChange = {(event) => handleRecursosCanciones(event)} type="text" id="recursos_cancion_youtube_3" placeholder="" />
                                <Form.Text className="text-danger">Advertencia: no se mostrará el video en la aplicación, solo el audio</Form.Text>
                            </Form.Group>

                            <Form.Group className="mb-2" >
                                <Form.Label>Actividad</Form.Label>
                                <br></br>
                                <Form.Text className="text-muted">Título</Form.Text>
                                <Form.Control onChange = {(event) => handleRecursosActividadTitulo(event)} type="text" id="recursos_actividad_titulo" placeholder="" />
                            </Form.Group>
                            <Form.Group className="mb-2" >
                                <Form.Text className="text-muted">Explicación</Form.Text>
                                <Form.Control onChange = {(event) => handleRecursosActividadExplicacion(event)} as="textarea" placeholder="" id="recursos_actividad_explicacion" rows={5} />
                            </Form.Group>

                            <Form.Group className="mb-5" >
                                <Form.Text className="text-muted">Imagen</Form.Text>
                                <Form.Control type="file"  onChange = {(event) => handleRecursosActividadImagen(event)}/>
                                <Form.Text className="text-danger">Formatos admitidos: PNG, JPG y JPEG</Form.Text>
                                <div id="container_recursos_actividad_imagenes"></div>
                            </Form.Group>

                            <div className="mb-4" style={{width:"100%", background:"#F4ABBA", textAlign:"center", paddingTop:20, paddingBottom:20}}>
                                <span><h5>Espiritualidad</h5></span>
                            </div>
                            <Form.Group className="mb-2" >
                                <Form.Label>Espiritualidad Dominicana</Form.Label>
                                <br></br>
                                <Form.Text className="text-muted">Texto</Form.Text>
                                <Form.Control onChange = {(event) => handleEspiritualidadDominicanaTexto(event)} as="textarea" placeholder="" id="espiritualidad_dominicana_texto" rows={5} />
                            </Form.Group>
                            <Form.Group className="mb-2" >
                                <Form.Text className="text-muted">Imágenes</Form.Text>
                                <Form.Control type="file" multiple onChange = {(event) => handleEspiritualidadDominicanaImagenes(event)}/>
                                <Form.Text className="text-danger">Formatos admitidos: PNG, JPG y JPEG</Form.Text>
                                <div id="container_espiritualidad_dominicana_imagenes"></div>
                            </Form.Group>

                            <Form.Group className="mb-4" >
                                <Row className="mb-2">
                                    <Col>
                                        <Form.Text className="text-muted">Texto</Form.Text>
                                        <Form.Control onChange = {(event) => handleEspiritualidadDominicanaEnlaces(event)} type="text" id="espiritualidad_dominicana_texto_1" placeholder="" />
                                    </Col>
                                    <Col>
                                        <Form.Text className="text-muted">Enlace</Form.Text>
                                        <Form.Control onChange = {(event) => handleEspiritualidadDominicanaEnlaces(event)} type="text" id="espiritualidad_dominicana_enlace_1" placeholder="" />
                                    </Col>
                                </Row>

                                <Row className="mb-2">
                                    <Col>
                                        <Form.Control onChange = {(event) => handleEspiritualidadDominicanaEnlaces(event)} type="text" id="espiritualidad_dominicana_texto_2" placeholder="" />
                                    </Col>
                                    <Col>
                                        <Form.Control onChange = {(event) => handleEspiritualidadDominicanaEnlaces(event)} type="text" id="espiritualidad_dominicana_enlace_2" placeholder="" />
                                    </Col>
                                </Row>

                                <Row className="mb-2">
                                    <Col>
                                        <Form.Control onChange = {(event) => handleEspiritualidadDominicanaEnlaces(event)} type="text" id="espiritualidad_dominicana_texto_3" placeholder="" />
                                    </Col>
                                    <Col>
                                        <Form.Control onChange = {(event) => handleEspiritualidadDominicanaEnlaces(event)} type="text" id="espiritualidad_dominicana_enlace_3" placeholder="" />
                                    </Col>
                                </Row>

                                <Row className="mb-2">
                                    <Col>
                                        <Form.Control onChange = {(event) => handleEspiritualidadDominicanaEnlaces(event)} type="text" id="espiritualidad_dominicana_texto_4" placeholder="" />
                                    </Col>
                                    <Col>
                                        <Form.Control onChange = {(event) => handleEspiritualidadDominicanaEnlaces(event)} type="text" id="espiritualidad_dominicana_enlace_4" placeholder="" />
                                    </Col>
                                </Row>

                                <Row >
                                    <Col>
                                        <Form.Control onChange = {(event) => handleEspiritualidadDominicanaEnlaces(event)} type="text" id="espiritualidad_dominicana_texto_5" placeholder="" />
                                    </Col>
                                    <Col>
                                        <Form.Control onChange = {(event) => handleEspiritualidadDominicanaEnlaces(event)} type="text" id="espiritualidad_dominicana_enlace_5" placeholder="" />
                                    </Col>
                                </Row>
                            </Form.Group>


                            <Form.Group className="mb-2" >
                                <Form.Label>Santo</Form.Label>
                                <br></br>
                                <Form.Text className="text-muted">Santo</Form.Text>
                                <Form.Control onChange = {(event) => handleEspiritualidadSantoSanto(event)} type="text" id="espiritualidad_santo" placeholder="" />
                            </Form.Group>
                            <Form.Group className="mb-2" >
                                <Form.Text className="text-muted">Texto</Form.Text>
                                <Form.Control onChange = {(event) => handleEspiritualidadSantoTexto(event)} as="textarea" placeholder="" id="espiritualidad_santo_texto" rows={5} />
                            </Form.Group>
                            <Form.Group className="mb-2" >
                                <Form.Text className="text-muted">Imágenes</Form.Text>
                                <Form.Control type="file" multiple onChange = {(event) => handleEspiritualidadSantoImagenes(event)}/>
                                <Form.Text className="text-danger">Formatos admitidos: PNG, JPG y JPEG</Form.Text>
                                <div id="container_espiritualidad_santo_imagenes"></div>
                            </Form.Group>
                            
                            <Form.Group className="mb-0" >
                                <Form.Text className="text-muted">Enlaces</Form.Text>
                            </Form.Group>
                            <Form.Group className="mb-4" >
                                <Row className="mb-2">
                                    <Col>
                                        <Form.Text className="text-muted">Texto</Form.Text>
                                        <Form.Control onChange = {(event) => handleEspiritualidadSantoEnlaces(event)} type="text" id="espiritualidad_santo_texto_1" placeholder="" />
                                    </Col>
                                    <Col>
                                        <Form.Text className="text-muted">Enlace</Form.Text>
                                        <Form.Control onChange = {(event) => handleEspiritualidadSantoEnlaces(event)} type="text" id="espiritualidad_santo_enlace_1" placeholder="" />
                                    </Col>
                                </Row>

                                <Row className="mb-2">
                                    <Col>
                                        <Form.Control onChange = {(event) => handleEspiritualidadSantoEnlaces(event)} type="text" id="espiritualidad_santo_texto_2" placeholder="" />
                                    </Col>
                                    <Col>
                                        <Form.Control onChange = {(event) => handleEspiritualidadSantoEnlaces(event)} type="text" id="espiritualidad_santo_enlace_2" placeholder="" />
                                    </Col>
                                </Row>

                                <Row >
                                    <Col>
                                        <Form.Control onChange = {(event) => handleEspiritualidadSantoEnlaces(event)} type="text" id="espiritualidad_santo_texto_3" placeholder="" />
                                    </Col>
                                    <Col>
                                        <Form.Control onChange = {(event) => handleEspiritualidadSantoEnlaces(event)} type="text" id="espiritualidad_santo_enlace_3" placeholder="" />
                                    </Col>
                                </Row>
                            </Form.Group>

                            <Form.Group className="mb-2" >
                                <Form.Label>Oración</Form.Label>
                                <br></br>
                                <Form.Text className="text-muted">Título</Form.Text>
                                <Form.Control onChange = {(event) => handleEspiritualidadOracionTitulo(event)} type="text" id="espiritualidad_oracion_titulo" placeholder="" />
                            </Form.Group>
                            <Form.Group className="mb-2" >
                                <Form.Text className="text-muted">Subtítulo</Form.Text>
                                <Form.Control onChange = {(event) => handleEspiritualidadOracionSubtitulo(event)} type="text" id="espiritualidad_oracion_subtitulo" placeholder="" />
                            </Form.Group>
                            <Form.Group className="mb-4" >
                                <Form.Text className="text-muted">Oración</Form.Text>
                                <Form.Control onChange = {(event) => handleEspiritualidadOracionOracion(event)} as="textarea" placeholder="" id="espiritualidad_oracion_texto" rows={5} />
                            </Form.Group>

                            <Form.Group className="mb-2" >
                                <Form.Label>Salmo</Form.Label>
                                <br></br>
                                <Form.Text className="text-muted">Título</Form.Text>
                                <Form.Control onChange = {(event) => handleEspiritualidadSalmoTitulo(event)} type="text" id="espiritualidad_salmo_titulo" placeholder="" />
                            </Form.Group>
                            <Form.Group className="mb-2" >
                                <Form.Text className="text-muted">Cita</Form.Text>
                                <Form.Control onChange = {(event) => handleEspiritualidadSalmoCita(event)} type="text" id="espiritualidad_salmo_cita" placeholder="" />
                            </Form.Group>
                            <Form.Group className="mb-2" >
                                <Form.Text className="text-muted">Salmo</Form.Text>
                                <Form.Control onChange = {(event) => handleEspiritualidadSalmoSalmo(event)} as="textarea" placeholder="" id="espiritualidad_salmo_texto" rows={5} />
                            </Form.Group>
                            <Form.Group className="mb-5" >
                                <Form.Text className="text-muted">Enlace YouTube</Form.Text>
                                <Form.Control onChange = {(event) => handleEspiritualidadSalmoYouTube(event)} type="text" id="espiritualidad_salmo_youtube" placeholder=""/>
                                <Form.Text className="text-danger">Advertencia: no se mostrará el video en la aplicación, solo el audio</Form.Text>
                            </Form.Group>

                            <div className="mb-4" style={{width:"100%", background:"#BFDFC7", textAlign:"center", paddingTop:20, paddingBottom:20}}>
                                <span><h5>Reflexión</h5></span>
                            </div>

                            <Form.Group className="mb-5" >
                                <Form.Text className="text-muted">Texto</Form.Text>
                                <Form.Control onChange = {(event) => handleReflexion(event)} as="textarea" placeholder="" id="reflexion" rows={5} />
                            </Form.Group>

                            <div className="mb-4" style={{width:"100%", background:"#BBD5D3", textAlign:"center", paddingTop:20, paddingBottom:20}}>
                                <span><h5>Bibliografía</h5></span>
                            </div>
                            <Form.Group className="mb-5" >
                                
                                <Row className="mb-2">
                                    <Col>
                                        <Form.Text className="text-muted">Texto</Form.Text>
                                        <Form.Control onChange = {(event) => handleBibliografia(event)} type="text" id="bibliografia_texto_1" placeholder="" />
                                    </Col>
                                    <Col>
                                        <Form.Text className="text-muted">Enlace</Form.Text>
                                        <Form.Control onChange = {(event) => handleBibliografia(event)} type="text" id="bibliografia_enlace_1" placeholder="" />
                                    </Col>
                                </Row>

                                <Row className="mb-2">
                                    <Col>
                                        <Form.Control onChange = {(event) => handleBibliografia(event)} type="text" id="bibliografia_texto_2" placeholder="" />
                                    </Col>
                                    <Col>
                                        <Form.Control onChange = {(event) => handleBibliografia(event)} type="text" id="bibliografia_enlace_2" placeholder="" />
                                    </Col>
                                </Row>

                                <Row className="mb-2">
                                    <Col>
                                        <Form.Control onChange = {(event) => handleBibliografia(event)} type="text" id="bibliografia_texto_3" placeholder="" />
                                    </Col>
                                    <Col>
                                        <Form.Control onChange = {(event) => handleBibliografia(event)} type="text" id="bibliografia_enlace_3" placeholder="" />
                                    </Col>
                                </Row>

                                <Row className="mb-2">
                                    <Col>
                                        <Form.Control onChange = {(event) => handleBibliografia(event)} type="text" id="bibliografia_texto_4" placeholder="" />
                                    </Col>
                                    <Col>
                                        <Form.Control onChange = {(event) => handleBibliografia(event)} type="text" id="bibliografia_enlace_4" placeholder="" />
                                    </Col>
                                </Row>

                                <Row className="mb-2">
                                    <Col>
                                        <Form.Control onChange = {(event) => handleBibliografia(event)} type="text" id="bibliografia_texto_5" placeholder="" />
                                    </Col>
                                    <Col>
                                        <Form.Control onChange = {(event) => handleBibliografia(event)} type="text" id="bibliografia_enlace_5" placeholder="" />
                                    </Col>
                                </Row>

                                <Row className="mb-2">
                                    <Col>
                                        <Form.Control onChange = {(event) => handleBibliografia(event)} type="text" id="bibliografia_texto_6" placeholder="" />
                                    </Col>
                                    <Col>
                                        <Form.Control onChange = {(event) => handleBibliografia(event)} type="text" id="bibliografia_enlace_6" placeholder="" />
                                    </Col>
                                </Row>

                                <Row className="mb-2">
                                    <Col>
                                        <Form.Control onChange = {(event) => handleBibliografia(event)} type="text" id="bibliografia_texto_7" placeholder="" />
                                    </Col>
                                    <Col>
                                        <Form.Control onChange = {(event) => handleBibliografia(event)} type="text" id="bibliografia_enlace_7" placeholder="" />
                                    </Col>
                                </Row>

                                <Row className="mb-2">
                                    <Col>
                                        <Form.Control onChange = {(event) => handleBibliografia(event)} type="text" id="bibliografia_texto_8" placeholder="" />
                                    </Col>
                                    <Col>
                                        <Form.Control onChange = {(event) => handleBibliografia(event)} type="text" id="bibliografia_enlace_8" placeholder="" />
                                    </Col>
                                </Row>

                                <Row className="mb-2">
                                    <Col>
                                        <Form.Control onChange = {(event) => handleBibliografia(event)} type="text" id="bibliografia_texto_9" placeholder="" />
                                    </Col>
                                    <Col>
                                        <Form.Control onChange = {(event) => handleBibliografia(event)} type="text" id="bibliografia_enlace_9" placeholder="" />
                                    </Col>
                                </Row>

                                <Row className="mb-0">
                                    <Col>
                                        <Form.Control onChange = {(event) => handleBibliografia(event)} type="text" id="bibliografia_texto_10" placeholder="" />
                                    </Col>
                                    <Col>
                                        <Form.Control onChange = {(event) => handleBibliografia(event)} type="text" id="bibliografia_enlace_10" placeholder="" />
                                    </Col>
                                </Row>
                            </Form.Group>

                            <Form.Group className="mb-5" >
                                {showButton()}
                            </Form.Group>
                        </Form>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}
