import React, {useEffect} from 'react'
// eslint-disable-next-line
import {BrowserRouter as Router, Switch, Route, Link} from "react-router-dom";
import {Button, Container, Row, Col, Card} from "react-bootstrap";
import {obtenerUnidades, eliminarUnidad} from '../../controlador/index.controller';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle, faPen, faTrash } from '@fortawesome/free-solid-svg-icons'
//import convertirABase64 from '../tools/convertirABase64';

export default function CrearUnidad(){
    const rutaArchivos = "http://formacion.secdevstest.com.ar/data";
    const [unidades = [], setUnidades] = React.useState();

    useEffect(() => {
        document.title = "Unit - Unidades";
        unidadesExistentes();
    }, []);

    const unidadesExistentes = async function(){
        const token = localStorage.getItem("user-token")
        const getUnidades = await obtenerUnidades(token);
        if(getUnidades.status === 200){
            const u = await getUnidades.json();
            setUnidades(u);
        }else if(getUnidades.status == 403){
            window.location.href = "/login";
        }
    }

    const handleEliminar = (unidadId, unidadNombre) => {
        eliminar(unidadId, unidadNombre);
    };

    const eliminar = async function(unidadId, unidadNombre){
        if (window.confirm("¿Estás seguro de eliminar la unidad " + unidadNombre + "?")) {
            const token = localStorage.getItem("user-token");
            const deleteUnidad = await eliminarUnidad(token, unidadId);

            if(deleteUnidad.status === 200){
                alert("Unidad eliminada correctamente");
                window.location.reload();
            }else{
                alert("Error al eliminar unidad. " + deleteUnidad.data + " " + deleteUnidad.message);
            }
        }
    }

    const verificarUnidades = function(){
        if(unidades.length === 0){
            return (
                <div style={{width:"100%", textAlign:"center", fontSize:25}}>
                    <p className="mt-5">No hay unidades existentes</p>
                </div>
            )
        }
    }

    return(
        <Container>
            <Row className="mb-4">
                <Col>
                    <h1>Unidades</h1>
                </Col>
                <Col style={{textAlign: "right"}} className="mt-2">
                    <Link to={"/unidades/nueva"}>
                        <Button variant="success"><FontAwesomeIcon icon={faPlusCircle} style={{marginRight: 5}} />Crear nueva unidad</Button>
                    </Link>
                </Col>
            </Row>

            <Row>
                {verificarUnidades()}
                {unidades.map((u) => (
                    <Col xs={3} className="mb-3">
                        <Card>
                            <Card.Img variant="top" src={rutaArchivos + u.imagen.uri} height={200} />
                            <Card.Body>
                                
                                <Card.Title>{u.nombre}</Card.Title>
                                <Card.Text>
                                    <p>{u.descripcion}</p>
                                    <hr></hr>
                                    <p>
                                        {u.unidades_ejes.map((ue) => (
                                            <p className="m-0">{ue.eje.nombre}</p>
                                        ))}
                                    </p>
                                    <hr></hr>
                                    <p>
                                        {u.unidades_secciones.map((us) => (
                                            <p className="m-0">{`${us.secciones_departamento.nombre}`}</p>
                                        ))}
                                    </p>
                                    <hr></hr>
                                </Card.Text>
                                <Link to={`/unidades/modificar/?unidad=${u.id}`}>
                                    <Button style={{marginRight:10}} className="w-25" variant="warning"><FontAwesomeIcon icon={faPen} /></Button>
                                </Link>

                                <Button variant="danger" className="w-25" onClick={() => handleEliminar(u.id, u.nombre)} href="#"><FontAwesomeIcon icon={faTrash} /></Button>
                            </Card.Body>
                        </Card>
                    </Col>

                ))}
              
            </Row>
        </Container>
    )

}
