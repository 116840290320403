import React, {useEffect} from 'react'

export default function UserController(){

    useEffect(() => {
        validarUsuario();
    }, []);
    
    const validarUsuario = function(){
        const token = localStorage.getItem("user-token");
        if(token !== undefined && token !== null){
            if(token.length > 10){
                //Usuario está logueado

                if(window.location.href === "https://formacion.secdevstest.com.ar/" || window.location.href  === "http://localhost:3000/"){
                    //Estoy en el inicio
                    window.location.href = "/unidades";
                }
                
            }else{
                window.location.href = "/login";
            }
        }else{
            window.location.href = "/login";
        }
    }

    return (
        <div></div>
    );
}
